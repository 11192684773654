import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export type Aggregation = {
  __typename?: 'Aggregation';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  percent: Scalars['Float']['output'];
};

export type Aggregations = {
  __typename?: 'Aggregations';
  assetsByType?: Maybe<Array<Maybe<Aggregation>>>;
  trackersByType?: Maybe<Array<Maybe<Aggregation>>>;
};

export type Asset = {
  __typename?: 'Asset';
  assetId: Scalars['Int']['output'];
  cameraId?: Maybe<Scalars['Int']['output']>;
  companyId: Scalars['Int']['output'];
  customModel?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Make>;
  model: Model;
  name: Scalars['String']['output'];
  serialNumber?: Maybe<Scalars['String']['output']>;
  trackerId?: Maybe<Scalars['Int']['output']>;
  type: AssetType;
  vin?: Maybe<Scalars['String']['output']>;
};

export type AssetCreatedNotification = Notification & {
  __typename?: 'AssetCreatedNotification';
  assetId: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  notificationType: NotificationType;
};

export type AssetDeletedNotification = Notification & {
  __typename?: 'AssetDeletedNotification';
  assetId: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  notificationType: NotificationType;
};

export type AssetSearchFilter = {
  /** A list of integers representing asset IDs that can be null. */
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** A list of integers representing camera IDs that can be null. */
  cameraIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** A list of integers representing tracker IDs that can be null. */
  trackerIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type AssetType = {
  __typename?: 'AssetType';
  assetTypeId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type AssetTypes = {
  __typename?: 'AssetTypes';
  items: Array<AssetType>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type AssetUpdatedNotification = Notification & {
  __typename?: 'AssetUpdatedNotification';
  assetId: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  notificationType: NotificationType;
};

export type Assets = {
  __typename?: 'Assets';
  items: Array<Asset>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type Branch = {
  __typename?: 'Branch';
  active: Scalars['Boolean']['output'];
  branchId: Scalars['Int']['output'];
  companyId: Scalars['Int']['output'];
  defaultBranch: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type Camera = {
  __typename?: 'Camera';
  assetId?: Maybe<Scalars['Int']['output']>;
  cameraId: Scalars['Int']['output'];
  cameraType?: Maybe<CameraType>;
  companyId?: Maybe<Scalars['Int']['output']>;
  deviceSerial?: Maybe<Scalars['String']['output']>;
};

export type CameraSearchFilter = {
  /** A list of integers representing asset IDs that can be null. */
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type CameraType = {
  __typename?: 'CameraType';
  cameraTypeId: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Cameras = {
  __typename?: 'Cameras';
  items: Array<Camera>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type ConfigurationSteps = {
  __typename?: 'ConfigurationSteps';
  assignHardware: StepStatus;
  createAsset: StepStatus;
  createGeofence: StepStatus;
  createGroup: StepStatus;
  createUser: StepStatus;
  installTracker: StepStatus;
  updateDefaultBranch: StepStatus;
};

export type ConfigurationStepsUpdatedNotification = Notification & {
  __typename?: 'ConfigurationStepsUpdatedNotification';
  companyId: Scalars['Int']['output'];
  notificationType: NotificationType;
};

export type ConfirmDeviceInstalled = {
  deviceId: Scalars['String']['input'];
};

export type Coordinate = {
  __typename?: 'Coordinate';
  current?: Maybe<Scalars['Float']['output']>;
  lastKnown?: Maybe<Scalars['Float']['output']>;
  lastKnownTimestamp?: Maybe<Scalars['String']['output']>;
};

export type CreateAsset = {
  assetTypeId: Scalars['Int']['input'];
  customName?: InputMaybe<Scalars['String']['input']>;
  makeId?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelId?: InputMaybe<Scalars['Int']['input']>;
  serial?: InputMaybe<Scalars['String']['input']>;
  vin?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['String']['input']>;
};

export type CreateResult = {
  __typename?: 'CreateResult';
  id: Scalars['Int']['output'];
};

export type CreateUser = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissions?: InputMaybe<CreateUserPermissions>;
  roleId: Scalars['Int']['input'];
};

export type CreateUserPermissions = {
  canAccessCamera?: InputMaybe<Scalars['Boolean']['input']>;
  canCreateAssetFinancialRecords?: InputMaybe<Scalars['Boolean']['input']>;
  canGrantPermissions?: InputMaybe<Scalars['Boolean']['input']>;
  canReadAssetFinancialRecords?: InputMaybe<Scalars['Boolean']['input']>;
  canRent?: InputMaybe<Scalars['Boolean']['input']>;
  hideAssetDetails?: InputMaybe<Scalars['Boolean']['input']>;
  readOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Device = {
  __typename?: 'Device';
  assetId?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  deviceId: Scalars['String']['output'];
  deviceSerial: Scalars['String']['output'];
  esId: Scalars['String']['output'];
  installDate?: Maybe<Scalars['String']['output']>;
  installStatuses: Array<DeviceInstallStatus>;
  kind?: Maybe<Scalars['String']['output']>;
  typeId?: Maybe<Scalars['Int']['output']>;
  typeName?: Maybe<Scalars['String']['output']>;
};

export enum DeviceInstallStatus {
  Assigned = 'ASSIGNED',
  Complete = 'COMPLETE',
  Detected = 'DETECTED',
  Unassigned = 'UNASSIGNED',
  Undetected = 'UNDETECTED'
}

export type DeviceSearchResult = {
  __typename?: 'DeviceSearchResult';
  facets?: Maybe<Array<Facet>>;
  items: Array<Device>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type Devices = {
  __typename?: 'Devices';
  items: Array<Device>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type Facet = {
  __typename?: 'Facet';
  entries: Array<FacetEntry>;
  facetId: FacetId;
};

export type FacetEntry = {
  __typename?: 'FacetEntry';
  count: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum FacetId {
  DeviceInstallStatus = 'DEVICE_INSTALL_STATUS',
  DeviceKind = 'DEVICE_KIND',
  DeviceType = 'DEVICE_TYPE'
}

export type Make = {
  __typename?: 'Make';
  makeId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  popularEquipmentMake?: Maybe<Scalars['Boolean']['output']>;
  popularVehicleMake?: Maybe<Scalars['Boolean']['output']>;
};

export type MakeSearchFilter = {
  assetTypeId?: InputMaybe<Scalars['Int']['input']>;
};

export type Makes = {
  __typename?: 'Makes';
  items: Array<Make>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type Me = {
  __typename?: 'Me';
  companyId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  permissions: Permissions;
  userId: Scalars['Int']['output'];
};

export type Model = {
  __typename?: 'Model';
  modelId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Models = {
  __typename?: 'Models';
  items: Array<Model>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  completeInstallDevices?: Maybe<Scalars['Void']['output']>;
  confirmDeviceInstalled?: Maybe<Scalars['Void']['output']>;
  createAsset: CreateResult;
  createUser: CreateResult;
  deleteConfigurationSteps?: Maybe<Scalars['Void']['output']>;
  setTrackerShipment?: Maybe<Scalars['Void']['output']>;
  updateAsset: Scalars['Boolean']['output'];
};


export type MutationConfirmDeviceInstalledArgs = {
  deviceId: Scalars['String']['input'];
};


export type MutationCreateAssetArgs = {
  input: CreateAsset;
};


export type MutationCreateUserArgs = {
  input: CreateUser;
};


export type MutationSetTrackerShipmentArgs = {
  input: TrackerShipment;
};


export type MutationUpdateAssetArgs = {
  assetId: Scalars['Int']['input'];
  input: UpdateAsset;
};

export type Notification = {
  companyId: Scalars['Int']['output'];
  notificationType: NotificationType;
};

export enum NotificationType {
  AssetCreatedNotification = 'AssetCreatedNotification',
  AssetDeletedNotification = 'AssetDeletedNotification',
  AssetUpdatedNotification = 'AssetUpdatedNotification',
  ConfigurationStepsUpdatedNotification = 'ConfigurationStepsUpdatedNotification'
}

/** Union type for different notification types */
export type NotificationUnion = AssetCreatedNotification | AssetDeletedNotification | AssetUpdatedNotification | ConfigurationStepsUpdatedNotification;

export type Permissions = {
  __typename?: 'Permissions';
  canAccessCamera: Scalars['Boolean']['output'];
  canCreateAssetFinancialRecords: Scalars['Boolean']['output'];
  canGrantPermissions: Scalars['Boolean']['output'];
  canReadAssetFinancialRecords: Scalars['Boolean']['output'];
  isCompanyOwner: Scalars['Boolean']['output'];
  readOnly: Scalars['Boolean']['output'];
  securityLevelId: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  aggregations: Aggregations;
  getAssetTypes: AssetTypes;
  getConfigurationSteps: ConfigurationSteps;
  getDefaultBranch: Branch;
  getDetailsFromVin: VinDetails;
  getTrackerState?: Maybe<TrackerState>;
  getUpstreamIngestionMetadata?: Maybe<UpstreamFleetIngestionMetadata>;
  me: Me;
  searchAssets: Assets;
  searchAssetsEsApi: Assets;
  searchCameras: Cameras;
  searchDevices: DeviceSearchResult;
  searchMakes: Makes;
  searchModels: Models;
  searchTrackers: Trackers;
};


export type QueryGetDetailsFromVinArgs = {
  vin: Scalars['String']['input'];
};


export type QueryGetTrackerStateArgs = {
  trackerId: Scalars['Int']['input'];
};


export type QuerySearchAssetsArgs = {
  filter?: InputMaybe<AssetSearchFilter>;
  input: SearchInput;
};


export type QuerySearchAssetsEsApiArgs = {
  input: SearchEsAssetInput;
};


export type QuerySearchCamerasArgs = {
  filter?: InputMaybe<CameraSearchFilter>;
  input: SearchInput;
};


export type QuerySearchDevicesArgs = {
  input: SearchInput;
};


export type QuerySearchMakesArgs = {
  filter?: InputMaybe<MakeSearchFilter>;
  input: SearchInput;
};


export type QuerySearchModelsArgs = {
  input: SearchInput;
  makeId: Scalars['Int']['input'];
};


export type QuerySearchTrackersArgs = {
  filter?: InputMaybe<TrackerSearchFilter>;
  input: SearchInput;
};

export type SearchEsAssetInput = {
  field: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  value: Scalars['String']['input'];
};

export type SearchFilter = {
  facetId: FacetId;
  value: Scalars['String']['input'];
};

export type SearchInput = {
  filters?: InputMaybe<Array<SearchFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  query: Scalars['String']['input'];
};

export enum StepStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Unavailable = 'UNAVAILABLE'
}

export type Subscription = {
  __typename?: 'Subscription';
  notification?: Maybe<NotificationUnion>;
};

export type Tracker = {
  __typename?: 'Tracker';
  assetId?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  deviceSerial?: Maybe<Scalars['String']['output']>;
  trackerId: Scalars['Int']['output'];
  trackerType?: Maybe<TrackerType>;
};

export type TrackerSearchFilter = {
  /** A list of integers representing asset IDs that can be null. */
  assetIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type TrackerShipment = {
  deviceSerial: Scalars['String']['input'];
  shipmentDate: Scalars['String']['input'];
};

export type TrackerState = {
  __typename?: 'TrackerState';
  batteryVoltage?: Maybe<Scalars['String']['output']>;
  eventThresholdTimestamp?: Maybe<Scalars['String']['output']>;
  lastCellularContact?: Maybe<Scalars['String']['output']>;
  lastEventTimestamp?: Maybe<Scalars['String']['output']>;
  lastGPSSync?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Coordinate>;
  longitude?: Maybe<Coordinate>;
  trackerBatteryVoltage?: Maybe<Scalars['String']['output']>;
};

export type TrackerType = {
  __typename?: 'TrackerType';
  name?: Maybe<Scalars['String']['output']>;
  trackerTypeId: Scalars['Int']['output'];
};

export type Trackers = {
  __typename?: 'Trackers';
  items: Array<Tracker>;
  pageNumber: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type UpdateAsset = {
  cameraId?: InputMaybe<Scalars['Int']['input']>;
  trackerId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpstreamFleetIngestionMetadata = {
  __typename?: 'UpstreamFleetIngestionMetadata';
  assetCount: Scalars['Int']['output'];
};

export type VinDetails = {
  __typename?: 'VinDetails';
  errors?: Maybe<Scalars['JSON']['output']>;
  metadata: VinMetadata;
  vehicle: VinVehicle;
};

export type VinMetadata = {
  __typename?: 'VinMetadata';
  make?: Maybe<VinMetadataMake>;
  model?: Maybe<VinMetadataModel>;
};

export type VinMetadataMake = {
  __typename?: 'VinMetadataMake';
  makeId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VinMetadataModel = {
  __typename?: 'VinMetadataModel';
  modelId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type VinVehicle = {
  __typename?: 'VinVehicle';
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  modelYear?: Maybe<Scalars['String']['output']>;
  vehicleType?: Maybe<Scalars['String']['output']>;
};

export type CreateAssetMutationVariables = Exact<{
  input: CreateAsset;
}>;


export type CreateAssetMutation = { __typename?: 'Mutation', createAsset: { __typename?: 'CreateResult', id: number } };

export type UpdateAssetMutationVariables = Exact<{
  input: UpdateAsset;
  assetId: Scalars['Int']['input'];
}>;


export type UpdateAssetMutation = { __typename?: 'Mutation', updateAsset: boolean };

export type CreateUserMutationVariables = Exact<{
  input: CreateUser;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'CreateResult', id: number } };

export type CompleteInstallDevicesMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteInstallDevicesMutation = { __typename?: 'Mutation', completeInstallDevices?: any | null };

export type ConfirmDeviceInstalledMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;


export type ConfirmDeviceInstalledMutation = { __typename?: 'Mutation', confirmDeviceInstalled?: any | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'Me', companyId: number, email: string, firstName?: string | null, lastName?: string | null, userId: number, permissions: { __typename?: 'Permissions', canAccessCamera: boolean, canCreateAssetFinancialRecords: boolean, canGrantPermissions: boolean, canReadAssetFinancialRecords: boolean, isCompanyOwner: boolean, readOnly: boolean, securityLevelId: number } } };

export type GetUpstreamIngestionMetadataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUpstreamIngestionMetadataQuery = { __typename?: 'Query', getUpstreamIngestionMetadata?: { __typename?: 'UpstreamFleetIngestionMetadata', assetCount: number } | null };

export type GetAggregateAssetsByTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAggregateAssetsByTypeQuery = { __typename?: 'Query', aggregations: { __typename?: 'Aggregations', assetsByType?: Array<{ __typename?: 'Aggregation', count: number, id: string, name: string } | null> | null } };

export type GetAggregateTrackersByTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAggregateTrackersByTypeQuery = { __typename?: 'Query', aggregations: { __typename?: 'Aggregations', trackersByType?: Array<{ __typename?: 'Aggregation', count: number, id: string, name: string } | null> | null } };

export type GetConfigurationStepsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationStepsQuery = { __typename?: 'Query', getConfigurationSteps: { __typename?: 'ConfigurationSteps', createUser: StepStatus, createAsset: StepStatus, installTracker: StepStatus, assignHardware: StepStatus, updateDefaultBranch: StepStatus, createGeofence: StepStatus, createGroup: StepStatus } };

export type GetDefaultBranchQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefaultBranchQuery = { __typename?: 'Query', getDefaultBranch: { __typename?: 'Branch', id: number, branchId: number, name: string, active: boolean, companyId: number, defaultBranch: boolean } };

export type GetDetailsFromVinQueryVariables = Exact<{
  vin: Scalars['String']['input'];
}>;


export type GetDetailsFromVinQuery = { __typename?: 'Query', getDetailsFromVin: { __typename?: 'VinDetails', errors?: any | null, vehicle: { __typename?: 'VinVehicle', make?: string | null, model?: string | null, modelYear?: string | null, vehicleType?: string | null }, metadata: { __typename?: 'VinMetadata', make?: { __typename?: 'VinMetadataMake', makeId?: number | null, name?: string | null } | null, model?: { __typename?: 'VinMetadataModel', modelId?: number | null, name?: string | null } | null } } };

export type GetTrackerStateQueryVariables = Exact<{
  trackerId: Scalars['Int']['input'];
}>;


export type GetTrackerStateQuery = { __typename?: 'Query', getTrackerState?: { __typename?: 'TrackerState', eventThresholdTimestamp?: string | null, lastEventTimestamp?: string | null, lastCellularContact?: string | null, lastGPSSync?: string | null, trackerBatteryVoltage?: string | null, latitude?: { __typename?: 'Coordinate', current?: number | null, lastKnown?: number | null, lastKnownTimestamp?: string | null } | null, longitude?: { __typename?: 'Coordinate', current?: number | null, lastKnown?: number | null, lastKnownTimestamp?: string | null } | null } | null };

export type SearchMakesQueryVariables = Exact<{
  input: SearchInput;
  filter?: InputMaybe<MakeSearchFilter>;
}>;


export type SearchMakesQuery = { __typename?: 'Query', searchMakes: { __typename?: 'Makes', pageSize: number, pageNumber: number, totalItems: number, items: Array<{ __typename?: 'Make', makeId?: number | null, name?: string | null }> } };

export type SearchModelsQueryVariables = Exact<{
  input: SearchInput;
  makeId: Scalars['Int']['input'];
}>;


export type SearchModelsQuery = { __typename?: 'Query', searchModels: { __typename?: 'Models', pageNumber: number, pageSize: number, totalItems: number, items: Array<{ __typename?: 'Model', modelId?: number | null, name?: string | null }> } };

export type SearchAssetsQueryVariables = Exact<{
  input: SearchInput;
  filter?: InputMaybe<AssetSearchFilter>;
}>;


export type SearchAssetsQuery = { __typename?: 'Query', searchAssets: { __typename?: 'Assets', pageNumber: number, pageSize: number, totalItems: number, items: Array<{ __typename?: 'Asset', assetId: number, companyId: number, customModel?: string | null, name: string, serialNumber?: string | null, vin?: string | null, make?: { __typename?: 'Make', makeId?: number | null, name?: string | null } | null, model: { __typename?: 'Model', modelId?: number | null, name?: string | null }, type: { __typename?: 'AssetType', assetTypeId: number, name: string } }> } };

export type SearchTrackersQueryVariables = Exact<{
  input: SearchInput;
  filter?: InputMaybe<TrackerSearchFilter>;
}>;


export type SearchTrackersQuery = { __typename?: 'Query', searchTrackers: { __typename?: 'Trackers', pageNumber: number, pageSize: number, totalItems: number, items: Array<{ __typename?: 'Tracker', assetId?: number | null, companyId?: number | null, deviceSerial?: string | null, trackerId: number, trackerType?: { __typename?: 'TrackerType', trackerTypeId: number, name?: string | null } | null }> } };

export type SearchCamerasQueryVariables = Exact<{
  input: SearchInput;
  filter?: InputMaybe<CameraSearchFilter>;
}>;


export type SearchCamerasQuery = { __typename?: 'Query', searchCameras: { __typename?: 'Cameras', pageNumber: number, pageSize: number, totalItems: number, items: Array<{ __typename?: 'Camera', assetId?: number | null, cameraId: number, companyId?: number | null, deviceSerial?: string | null, cameraType?: { __typename?: 'CameraType', cameraTypeId: number, name?: string | null } | null }> } };

export type SearchDevicesQueryVariables = Exact<{
  input: SearchInput;
}>;


export type SearchDevicesQuery = { __typename?: 'Query', searchDevices: { __typename?: 'DeviceSearchResult', pageNumber: number, pageSize: number, totalItems: number, facets?: Array<{ __typename?: 'Facet', facetId: FacetId, entries: Array<{ __typename?: 'FacetEntry', count: number, id: string, name: string }> }> | null, items: Array<{ __typename?: 'Device', assetId?: number | null, deviceId: string, deviceSerial: string, esId: string, installDate?: string | null, installStatuses: Array<DeviceInstallStatus>, kind?: string | null, typeName?: string | null }> } };

export type SearchAssetsEsApiQueryVariables = Exact<{
  input: SearchEsAssetInput;
}>;


export type SearchAssetsEsApiQuery = { __typename?: 'Query', searchAssetsEsApi: { __typename?: 'Assets', items: Array<{ __typename?: 'Asset', assetId: number, customModel?: string | null, name: string, serialNumber?: string | null, vin?: string | null, make?: { __typename?: 'Make', name?: string | null } | null, model: { __typename?: 'Model', name?: string | null } }> } };

export type DeviceInstallStatusQueryVariables = Exact<{
  input: SearchInput;
}>;


export type DeviceInstallStatusQuery = { __typename?: 'Query', searchDevices: { __typename?: 'DeviceSearchResult', totalItems: number, facets?: Array<{ __typename?: 'Facet', facetId: FacetId, entries: Array<{ __typename?: 'FacetEntry', id: string, count: number }> }> | null } };

export type NotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationsSubscription = { __typename?: 'Subscription', notification?: { __typename?: 'AssetCreatedNotification', companyId: number, notificationType: NotificationType, assetId: number } | { __typename?: 'AssetDeletedNotification' } | { __typename?: 'AssetUpdatedNotification', companyId: number, notificationType: NotificationType, assetId: number } | { __typename?: 'ConfigurationStepsUpdatedNotification', companyId: number, notificationType: NotificationType } | null };


export const CreateAssetDocument = gql`
    mutation CreateAsset($input: CreateAsset!) {
  createAsset(input: $input) {
    id
  }
}
    `;
export type CreateAssetMutationFn = Apollo.MutationFunction<CreateAssetMutation, CreateAssetMutationVariables>;

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(CreateAssetDocument, options);
      }
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>;
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<CreateAssetMutation, CreateAssetMutationVariables>;
export const UpdateAssetDocument = gql`
    mutation UpdateAsset($input: UpdateAsset!, $assetId: Int!) {
  updateAsset(input: $input, assetId: $assetId)
}
    `;
export type UpdateAssetMutationFn = Apollo.MutationFunction<UpdateAssetMutation, UpdateAssetMutationVariables>;

/**
 * __useUpdateAssetMutation__
 *
 * To run a mutation, you first call `useUpdateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetMutation, { data, loading, error }] = useUpdateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      assetId: // value for 'assetId'
 *   },
 * });
 */
export function useUpdateAssetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetMutation, UpdateAssetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetMutation, UpdateAssetMutationVariables>(UpdateAssetDocument, options);
      }
export type UpdateAssetMutationHookResult = ReturnType<typeof useUpdateAssetMutation>;
export type UpdateAssetMutationResult = Apollo.MutationResult<UpdateAssetMutation>;
export type UpdateAssetMutationOptions = Apollo.BaseMutationOptions<UpdateAssetMutation, UpdateAssetMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUser!) {
  createUser(input: $input) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CompleteInstallDevicesDocument = gql`
    mutation CompleteInstallDevices {
  completeInstallDevices
}
    `;
export type CompleteInstallDevicesMutationFn = Apollo.MutationFunction<CompleteInstallDevicesMutation, CompleteInstallDevicesMutationVariables>;

/**
 * __useCompleteInstallDevicesMutation__
 *
 * To run a mutation, you first call `useCompleteInstallDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInstallDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInstallDevicesMutation, { data, loading, error }] = useCompleteInstallDevicesMutation({
 *   variables: {
 *   },
 * });
 */
export function useCompleteInstallDevicesMutation(baseOptions?: Apollo.MutationHookOptions<CompleteInstallDevicesMutation, CompleteInstallDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteInstallDevicesMutation, CompleteInstallDevicesMutationVariables>(CompleteInstallDevicesDocument, options);
      }
export type CompleteInstallDevicesMutationHookResult = ReturnType<typeof useCompleteInstallDevicesMutation>;
export type CompleteInstallDevicesMutationResult = Apollo.MutationResult<CompleteInstallDevicesMutation>;
export type CompleteInstallDevicesMutationOptions = Apollo.BaseMutationOptions<CompleteInstallDevicesMutation, CompleteInstallDevicesMutationVariables>;
export const ConfirmDeviceInstalledDocument = gql`
    mutation ConfirmDeviceInstalled($deviceId: String!) {
  confirmDeviceInstalled(deviceId: $deviceId)
}
    `;
export type ConfirmDeviceInstalledMutationFn = Apollo.MutationFunction<ConfirmDeviceInstalledMutation, ConfirmDeviceInstalledMutationVariables>;

/**
 * __useConfirmDeviceInstalledMutation__
 *
 * To run a mutation, you first call `useConfirmDeviceInstalledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmDeviceInstalledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmDeviceInstalledMutation, { data, loading, error }] = useConfirmDeviceInstalledMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useConfirmDeviceInstalledMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmDeviceInstalledMutation, ConfirmDeviceInstalledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmDeviceInstalledMutation, ConfirmDeviceInstalledMutationVariables>(ConfirmDeviceInstalledDocument, options);
      }
export type ConfirmDeviceInstalledMutationHookResult = ReturnType<typeof useConfirmDeviceInstalledMutation>;
export type ConfirmDeviceInstalledMutationResult = Apollo.MutationResult<ConfirmDeviceInstalledMutation>;
export type ConfirmDeviceInstalledMutationOptions = Apollo.BaseMutationOptions<ConfirmDeviceInstalledMutation, ConfirmDeviceInstalledMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    companyId
    email
    firstName
    lastName
    permissions {
      canAccessCamera
      canCreateAssetFinancialRecords
      canGrantPermissions
      canReadAssetFinancialRecords
      isCompanyOwner
      readOnly
      securityLevelId
    }
    userId
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetUpstreamIngestionMetadataDocument = gql`
    query GetUpstreamIngestionMetadata {
  getUpstreamIngestionMetadata {
    assetCount
  }
}
    `;

/**
 * __useGetUpstreamIngestionMetadataQuery__
 *
 * To run a query within a React component, call `useGetUpstreamIngestionMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpstreamIngestionMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpstreamIngestionMetadataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUpstreamIngestionMetadataQuery(baseOptions?: Apollo.QueryHookOptions<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>(GetUpstreamIngestionMetadataDocument, options);
      }
export function useGetUpstreamIngestionMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>(GetUpstreamIngestionMetadataDocument, options);
        }
export function useGetUpstreamIngestionMetadataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>(GetUpstreamIngestionMetadataDocument, options);
        }
export type GetUpstreamIngestionMetadataQueryHookResult = ReturnType<typeof useGetUpstreamIngestionMetadataQuery>;
export type GetUpstreamIngestionMetadataLazyQueryHookResult = ReturnType<typeof useGetUpstreamIngestionMetadataLazyQuery>;
export type GetUpstreamIngestionMetadataSuspenseQueryHookResult = ReturnType<typeof useGetUpstreamIngestionMetadataSuspenseQuery>;
export type GetUpstreamIngestionMetadataQueryResult = Apollo.QueryResult<GetUpstreamIngestionMetadataQuery, GetUpstreamIngestionMetadataQueryVariables>;
export const GetAggregateAssetsByTypeDocument = gql`
    query GetAggregateAssetsByType {
  aggregations {
    assetsByType {
      count
      id
      name
    }
  }
}
    `;

/**
 * __useGetAggregateAssetsByTypeQuery__
 *
 * To run a query within a React component, call `useGetAggregateAssetsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateAssetsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateAssetsByTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAggregateAssetsByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>(GetAggregateAssetsByTypeDocument, options);
      }
export function useGetAggregateAssetsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>(GetAggregateAssetsByTypeDocument, options);
        }
export function useGetAggregateAssetsByTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>(GetAggregateAssetsByTypeDocument, options);
        }
export type GetAggregateAssetsByTypeQueryHookResult = ReturnType<typeof useGetAggregateAssetsByTypeQuery>;
export type GetAggregateAssetsByTypeLazyQueryHookResult = ReturnType<typeof useGetAggregateAssetsByTypeLazyQuery>;
export type GetAggregateAssetsByTypeSuspenseQueryHookResult = ReturnType<typeof useGetAggregateAssetsByTypeSuspenseQuery>;
export type GetAggregateAssetsByTypeQueryResult = Apollo.QueryResult<GetAggregateAssetsByTypeQuery, GetAggregateAssetsByTypeQueryVariables>;
export const GetAggregateTrackersByTypeDocument = gql`
    query GetAggregateTrackersByType {
  aggregations {
    trackersByType {
      count
      id
      name
    }
  }
}
    `;

/**
 * __useGetAggregateTrackersByTypeQuery__
 *
 * To run a query within a React component, call `useGetAggregateTrackersByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAggregateTrackersByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAggregateTrackersByTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAggregateTrackersByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>(GetAggregateTrackersByTypeDocument, options);
      }
export function useGetAggregateTrackersByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>(GetAggregateTrackersByTypeDocument, options);
        }
export function useGetAggregateTrackersByTypeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>(GetAggregateTrackersByTypeDocument, options);
        }
export type GetAggregateTrackersByTypeQueryHookResult = ReturnType<typeof useGetAggregateTrackersByTypeQuery>;
export type GetAggregateTrackersByTypeLazyQueryHookResult = ReturnType<typeof useGetAggregateTrackersByTypeLazyQuery>;
export type GetAggregateTrackersByTypeSuspenseQueryHookResult = ReturnType<typeof useGetAggregateTrackersByTypeSuspenseQuery>;
export type GetAggregateTrackersByTypeQueryResult = Apollo.QueryResult<GetAggregateTrackersByTypeQuery, GetAggregateTrackersByTypeQueryVariables>;
export const GetConfigurationStepsDocument = gql`
    query GetConfigurationSteps {
  getConfigurationSteps {
    createUser
    createAsset
    installTracker
    assignHardware
    updateDefaultBranch
    createGeofence
    createGroup
  }
}
    `;

/**
 * __useGetConfigurationStepsQuery__
 *
 * To run a query within a React component, call `useGetConfigurationStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationStepsQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>(GetConfigurationStepsDocument, options);
      }
export function useGetConfigurationStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>(GetConfigurationStepsDocument, options);
        }
export function useGetConfigurationStepsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>(GetConfigurationStepsDocument, options);
        }
export type GetConfigurationStepsQueryHookResult = ReturnType<typeof useGetConfigurationStepsQuery>;
export type GetConfigurationStepsLazyQueryHookResult = ReturnType<typeof useGetConfigurationStepsLazyQuery>;
export type GetConfigurationStepsSuspenseQueryHookResult = ReturnType<typeof useGetConfigurationStepsSuspenseQuery>;
export type GetConfigurationStepsQueryResult = Apollo.QueryResult<GetConfigurationStepsQuery, GetConfigurationStepsQueryVariables>;
export const GetDefaultBranchDocument = gql`
    query GetDefaultBranch {
  getDefaultBranch {
    id
    branchId
    name
    active
    companyId
    defaultBranch
  }
}
    `;

/**
 * __useGetDefaultBranchQuery__
 *
 * To run a query within a React component, call `useGetDefaultBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultBranchQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultBranchQuery(baseOptions?: Apollo.QueryHookOptions<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>(GetDefaultBranchDocument, options);
      }
export function useGetDefaultBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>(GetDefaultBranchDocument, options);
        }
export function useGetDefaultBranchSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>(GetDefaultBranchDocument, options);
        }
export type GetDefaultBranchQueryHookResult = ReturnType<typeof useGetDefaultBranchQuery>;
export type GetDefaultBranchLazyQueryHookResult = ReturnType<typeof useGetDefaultBranchLazyQuery>;
export type GetDefaultBranchSuspenseQueryHookResult = ReturnType<typeof useGetDefaultBranchSuspenseQuery>;
export type GetDefaultBranchQueryResult = Apollo.QueryResult<GetDefaultBranchQuery, GetDefaultBranchQueryVariables>;
export const GetDetailsFromVinDocument = gql`
    query GetDetailsFromVin($vin: String!) {
  getDetailsFromVin(vin: $vin) {
    errors
    vehicle {
      make
      model
      modelYear
      vehicleType
    }
    metadata {
      make {
        makeId
        name
      }
      model {
        modelId
        name
      }
    }
  }
}
    `;

/**
 * __useGetDetailsFromVinQuery__
 *
 * To run a query within a React component, call `useGetDetailsFromVinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailsFromVinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailsFromVinQuery({
 *   variables: {
 *      vin: // value for 'vin'
 *   },
 * });
 */
export function useGetDetailsFromVinQuery(baseOptions: Apollo.QueryHookOptions<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>(GetDetailsFromVinDocument, options);
      }
export function useGetDetailsFromVinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>(GetDetailsFromVinDocument, options);
        }
export function useGetDetailsFromVinSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>(GetDetailsFromVinDocument, options);
        }
export type GetDetailsFromVinQueryHookResult = ReturnType<typeof useGetDetailsFromVinQuery>;
export type GetDetailsFromVinLazyQueryHookResult = ReturnType<typeof useGetDetailsFromVinLazyQuery>;
export type GetDetailsFromVinSuspenseQueryHookResult = ReturnType<typeof useGetDetailsFromVinSuspenseQuery>;
export type GetDetailsFromVinQueryResult = Apollo.QueryResult<GetDetailsFromVinQuery, GetDetailsFromVinQueryVariables>;
export const GetTrackerStateDocument = gql`
    query GetTrackerState($trackerId: Int!) {
  getTrackerState(trackerId: $trackerId) {
    eventThresholdTimestamp
    lastEventTimestamp
    lastCellularContact
    lastGPSSync
    latitude {
      current
      lastKnown
      lastKnownTimestamp
    }
    longitude {
      current
      lastKnown
      lastKnownTimestamp
    }
    trackerBatteryVoltage
  }
}
    `;

/**
 * __useGetTrackerStateQuery__
 *
 * To run a query within a React component, call `useGetTrackerStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackerStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackerStateQuery({
 *   variables: {
 *      trackerId: // value for 'trackerId'
 *   },
 * });
 */
export function useGetTrackerStateQuery(baseOptions: Apollo.QueryHookOptions<GetTrackerStateQuery, GetTrackerStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrackerStateQuery, GetTrackerStateQueryVariables>(GetTrackerStateDocument, options);
      }
export function useGetTrackerStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrackerStateQuery, GetTrackerStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrackerStateQuery, GetTrackerStateQueryVariables>(GetTrackerStateDocument, options);
        }
export function useGetTrackerStateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTrackerStateQuery, GetTrackerStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTrackerStateQuery, GetTrackerStateQueryVariables>(GetTrackerStateDocument, options);
        }
export type GetTrackerStateQueryHookResult = ReturnType<typeof useGetTrackerStateQuery>;
export type GetTrackerStateLazyQueryHookResult = ReturnType<typeof useGetTrackerStateLazyQuery>;
export type GetTrackerStateSuspenseQueryHookResult = ReturnType<typeof useGetTrackerStateSuspenseQuery>;
export type GetTrackerStateQueryResult = Apollo.QueryResult<GetTrackerStateQuery, GetTrackerStateQueryVariables>;
export const SearchMakesDocument = gql`
    query SearchMakes($input: SearchInput!, $filter: MakeSearchFilter) {
  searchMakes(input: $input, filter: $filter) {
    items {
      makeId
      name
    }
    pageSize
    pageNumber
    totalItems
  }
}
    `;

/**
 * __useSearchMakesQuery__
 *
 * To run a query within a React component, call `useSearchMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchMakesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchMakesQuery(baseOptions: Apollo.QueryHookOptions<SearchMakesQuery, SearchMakesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchMakesQuery, SearchMakesQueryVariables>(SearchMakesDocument, options);
      }
export function useSearchMakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchMakesQuery, SearchMakesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchMakesQuery, SearchMakesQueryVariables>(SearchMakesDocument, options);
        }
export function useSearchMakesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchMakesQuery, SearchMakesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchMakesQuery, SearchMakesQueryVariables>(SearchMakesDocument, options);
        }
export type SearchMakesQueryHookResult = ReturnType<typeof useSearchMakesQuery>;
export type SearchMakesLazyQueryHookResult = ReturnType<typeof useSearchMakesLazyQuery>;
export type SearchMakesSuspenseQueryHookResult = ReturnType<typeof useSearchMakesSuspenseQuery>;
export type SearchMakesQueryResult = Apollo.QueryResult<SearchMakesQuery, SearchMakesQueryVariables>;
export const SearchModelsDocument = gql`
    query SearchModels($input: SearchInput!, $makeId: Int!) {
  searchModels(input: $input, makeId: $makeId) {
    items {
      modelId
      name
    }
    pageNumber
    pageSize
    totalItems
  }
}
    `;

/**
 * __useSearchModelsQuery__
 *
 * To run a query within a React component, call `useSearchModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchModelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      makeId: // value for 'makeId'
 *   },
 * });
 */
export function useSearchModelsQuery(baseOptions: Apollo.QueryHookOptions<SearchModelsQuery, SearchModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchModelsQuery, SearchModelsQueryVariables>(SearchModelsDocument, options);
      }
export function useSearchModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchModelsQuery, SearchModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchModelsQuery, SearchModelsQueryVariables>(SearchModelsDocument, options);
        }
export function useSearchModelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchModelsQuery, SearchModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchModelsQuery, SearchModelsQueryVariables>(SearchModelsDocument, options);
        }
export type SearchModelsQueryHookResult = ReturnType<typeof useSearchModelsQuery>;
export type SearchModelsLazyQueryHookResult = ReturnType<typeof useSearchModelsLazyQuery>;
export type SearchModelsSuspenseQueryHookResult = ReturnType<typeof useSearchModelsSuspenseQuery>;
export type SearchModelsQueryResult = Apollo.QueryResult<SearchModelsQuery, SearchModelsQueryVariables>;
export const SearchAssetsDocument = gql`
    query SearchAssets($input: SearchInput!, $filter: AssetSearchFilter) {
  searchAssets(input: $input, filter: $filter) {
    items {
      assetId
      companyId
      customModel
      make {
        makeId
        name
      }
      model {
        modelId
        name
      }
      name
      serialNumber
      type {
        assetTypeId
        name
      }
      vin
    }
    pageNumber
    pageSize
    totalItems
  }
}
    `;

/**
 * __useSearchAssetsQuery__
 *
 * To run a query within a React component, call `useSearchAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchAssetsQuery(baseOptions: Apollo.QueryHookOptions<SearchAssetsQuery, SearchAssetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAssetsQuery, SearchAssetsQueryVariables>(SearchAssetsDocument, options);
      }
export function useSearchAssetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAssetsQuery, SearchAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAssetsQuery, SearchAssetsQueryVariables>(SearchAssetsDocument, options);
        }
export function useSearchAssetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchAssetsQuery, SearchAssetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchAssetsQuery, SearchAssetsQueryVariables>(SearchAssetsDocument, options);
        }
export type SearchAssetsQueryHookResult = ReturnType<typeof useSearchAssetsQuery>;
export type SearchAssetsLazyQueryHookResult = ReturnType<typeof useSearchAssetsLazyQuery>;
export type SearchAssetsSuspenseQueryHookResult = ReturnType<typeof useSearchAssetsSuspenseQuery>;
export type SearchAssetsQueryResult = Apollo.QueryResult<SearchAssetsQuery, SearchAssetsQueryVariables>;
export const SearchTrackersDocument = gql`
    query SearchTrackers($input: SearchInput!, $filter: TrackerSearchFilter) {
  searchTrackers(input: $input, filter: $filter) {
    items {
      assetId
      companyId
      deviceSerial
      trackerId
      trackerType {
        trackerTypeId
        name
      }
    }
    pageNumber
    pageSize
    totalItems
  }
}
    `;

/**
 * __useSearchTrackersQuery__
 *
 * To run a query within a React component, call `useSearchTrackersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchTrackersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchTrackersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchTrackersQuery(baseOptions: Apollo.QueryHookOptions<SearchTrackersQuery, SearchTrackersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchTrackersQuery, SearchTrackersQueryVariables>(SearchTrackersDocument, options);
      }
export function useSearchTrackersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchTrackersQuery, SearchTrackersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchTrackersQuery, SearchTrackersQueryVariables>(SearchTrackersDocument, options);
        }
export function useSearchTrackersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchTrackersQuery, SearchTrackersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchTrackersQuery, SearchTrackersQueryVariables>(SearchTrackersDocument, options);
        }
export type SearchTrackersQueryHookResult = ReturnType<typeof useSearchTrackersQuery>;
export type SearchTrackersLazyQueryHookResult = ReturnType<typeof useSearchTrackersLazyQuery>;
export type SearchTrackersSuspenseQueryHookResult = ReturnType<typeof useSearchTrackersSuspenseQuery>;
export type SearchTrackersQueryResult = Apollo.QueryResult<SearchTrackersQuery, SearchTrackersQueryVariables>;
export const SearchCamerasDocument = gql`
    query SearchCameras($input: SearchInput!, $filter: CameraSearchFilter) {
  searchCameras(input: $input, filter: $filter) {
    items {
      assetId
      cameraId
      companyId
      deviceSerial
      cameraType {
        cameraTypeId
        name
      }
    }
    pageNumber
    pageSize
    totalItems
  }
}
    `;

/**
 * __useSearchCamerasQuery__
 *
 * To run a query within a React component, call `useSearchCamerasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCamerasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCamerasQuery({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchCamerasQuery(baseOptions: Apollo.QueryHookOptions<SearchCamerasQuery, SearchCamerasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchCamerasQuery, SearchCamerasQueryVariables>(SearchCamerasDocument, options);
      }
export function useSearchCamerasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchCamerasQuery, SearchCamerasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchCamerasQuery, SearchCamerasQueryVariables>(SearchCamerasDocument, options);
        }
export function useSearchCamerasSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCamerasQuery, SearchCamerasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchCamerasQuery, SearchCamerasQueryVariables>(SearchCamerasDocument, options);
        }
export type SearchCamerasQueryHookResult = ReturnType<typeof useSearchCamerasQuery>;
export type SearchCamerasLazyQueryHookResult = ReturnType<typeof useSearchCamerasLazyQuery>;
export type SearchCamerasSuspenseQueryHookResult = ReturnType<typeof useSearchCamerasSuspenseQuery>;
export type SearchCamerasQueryResult = Apollo.QueryResult<SearchCamerasQuery, SearchCamerasQueryVariables>;
export const SearchDevicesDocument = gql`
    query SearchDevices($input: SearchInput!) {
  searchDevices(input: $input) {
    facets {
      entries {
        count
        id
        name
      }
      facetId
    }
    items {
      assetId
      deviceId
      deviceSerial
      esId
      installDate
      installStatuses
      kind
      typeName
    }
    pageNumber
    pageSize
    totalItems
  }
}
    `;

/**
 * __useSearchDevicesQuery__
 *
 * To run a query within a React component, call `useSearchDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDevicesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchDevicesQuery(baseOptions: Apollo.QueryHookOptions<SearchDevicesQuery, SearchDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDevicesQuery, SearchDevicesQueryVariables>(SearchDevicesDocument, options);
      }
export function useSearchDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDevicesQuery, SearchDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDevicesQuery, SearchDevicesQueryVariables>(SearchDevicesDocument, options);
        }
export function useSearchDevicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchDevicesQuery, SearchDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchDevicesQuery, SearchDevicesQueryVariables>(SearchDevicesDocument, options);
        }
export type SearchDevicesQueryHookResult = ReturnType<typeof useSearchDevicesQuery>;
export type SearchDevicesLazyQueryHookResult = ReturnType<typeof useSearchDevicesLazyQuery>;
export type SearchDevicesSuspenseQueryHookResult = ReturnType<typeof useSearchDevicesSuspenseQuery>;
export type SearchDevicesQueryResult = Apollo.QueryResult<SearchDevicesQuery, SearchDevicesQueryVariables>;
export const SearchAssetsEsApiDocument = gql`
    query SearchAssetsEsApi($input: SearchEsAssetInput!) {
  searchAssetsEsApi(input: $input) {
    items {
      assetId
      customModel
      make {
        name
      }
      model {
        name
      }
      name
      serialNumber
      vin
    }
  }
}
    `;

/**
 * __useSearchAssetsEsApiQuery__
 *
 * To run a query within a React component, call `useSearchAssetsEsApiQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAssetsEsApiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAssetsEsApiQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchAssetsEsApiQuery(baseOptions: Apollo.QueryHookOptions<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>(SearchAssetsEsApiDocument, options);
      }
export function useSearchAssetsEsApiLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>(SearchAssetsEsApiDocument, options);
        }
export function useSearchAssetsEsApiSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>(SearchAssetsEsApiDocument, options);
        }
export type SearchAssetsEsApiQueryHookResult = ReturnType<typeof useSearchAssetsEsApiQuery>;
export type SearchAssetsEsApiLazyQueryHookResult = ReturnType<typeof useSearchAssetsEsApiLazyQuery>;
export type SearchAssetsEsApiSuspenseQueryHookResult = ReturnType<typeof useSearchAssetsEsApiSuspenseQuery>;
export type SearchAssetsEsApiQueryResult = Apollo.QueryResult<SearchAssetsEsApiQuery, SearchAssetsEsApiQueryVariables>;
export const DeviceInstallStatusDocument = gql`
    query DeviceInstallStatus($input: SearchInput!) {
  searchDevices(input: $input) {
    facets {
      entries {
        id
        count
      }
      facetId
    }
    totalItems
  }
}
    `;

/**
 * __useDeviceInstallStatusQuery__
 *
 * To run a query within a React component, call `useDeviceInstallStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceInstallStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceInstallStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeviceInstallStatusQuery(baseOptions: Apollo.QueryHookOptions<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>(DeviceInstallStatusDocument, options);
      }
export function useDeviceInstallStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>(DeviceInstallStatusDocument, options);
        }
export function useDeviceInstallStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>(DeviceInstallStatusDocument, options);
        }
export type DeviceInstallStatusQueryHookResult = ReturnType<typeof useDeviceInstallStatusQuery>;
export type DeviceInstallStatusLazyQueryHookResult = ReturnType<typeof useDeviceInstallStatusLazyQuery>;
export type DeviceInstallStatusSuspenseQueryHookResult = ReturnType<typeof useDeviceInstallStatusSuspenseQuery>;
export type DeviceInstallStatusQueryResult = Apollo.QueryResult<DeviceInstallStatusQuery, DeviceInstallStatusQueryVariables>;
export const NotificationsDocument = gql`
    subscription Notifications {
  notification {
    ... on AssetCreatedNotification {
      companyId
      notificationType
      assetId
    }
    ... on AssetUpdatedNotification {
      companyId
      notificationType
      assetId
    }
    ... on ConfigurationStepsUpdatedNotification {
      companyId
      notificationType
    }
  }
}
    `;

/**
 * __useNotificationsSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationsSubscription, NotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationsSubscription, NotificationsSubscriptionVariables>(NotificationsDocument, options);
      }
export type NotificationsSubscriptionHookResult = ReturnType<typeof useNotificationsSubscription>;
export type NotificationsSubscriptionResult = Apollo.SubscriptionResult<NotificationsSubscription>;