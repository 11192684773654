export enum Environment {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

export const DEFAULT_PAGE_SIZE = 20;

const DEFAULT_SEARCH_QUERY_OPTIONS = {
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
};

const FLEET_CONFIGURATION_GRAPHQL_PATH =
  process.env.NEXT_PUBLIC_FLEET_CONFIGURATION_GRAPHQL_PATH ||
  "https://staging-api.equipmentshare.com/fleet-configuration-graphql/";

const FLEET_CONFIGURATION_GRAPHQL_NOTIFICATIONS_PATH =
  process.env.NEXT_PUBLIC_FLEET_CONFIGURATION_GRAPHQL_NOTIFICATIONS_PATH ||
  "https://staging-api.equipmentshare.com/fleet-configuration-graphql-notifications/";

const POLLING_INTERVAL = {
  VERY_HIGH: 1000,
  HIGH: 5000,
  MEDIUM: 10000,
  LOW: 30000,
  VERY_LOW: 60000,
} as const;

export const CLIENT_CONSTANTS = {
  Environment,
  DEFAULT_SEARCH_QUERY_OPTIONS,
  FLEET_CONFIGURATION_GRAPHQL_PATH,
  FLEET_CONFIGURATION_GRAPHQL_NOTIFICATIONS_PATH,
  POLLING_INTERVAL,
} as const;
