/**
 * Vercel Configuration Constants
 */
const VERCEL_SCOPE = {
  EQUIPMENTSHARE: "equipmentshare",
} as const;

const VERCEL_ORG_ID = {
  DEFAULT: process.env.VERCEL_ORG_ID_DEFAULT,
} as const;

/**
 * Project Configuration
 */
type ProjectConfig = {
  ALIAS: string;
  ID: string;
  NAME: string;
};

const VERCEL_PROJECT: Record<string, ProjectConfig> = {
  FLEET_CONFIGURATION_DASHBOARD: {
    ALIAS: "fleet-configuration-dashboard.estrack.com",
    ID: process.env.VERCEL_FLEET_CONFIGURATION_DASHBOARD_ID!,
    NAME: "fleet-configuration-dashboard",
  },
} as const;

/**
 * Application Deployment Configurations
 */
export type ApplicationDeploymentConfig = {
  ORG_ID: (typeof VERCEL_ORG_ID)[keyof typeof VERCEL_ORG_ID];
  PROJECT_ID: string;
  PROJECT_NAME: string;
  DOMAIN: string;
  SCOPE: (typeof VERCEL_SCOPE)[keyof typeof VERCEL_SCOPE];
};

const APPLICATION_DEPLOYMENT_CONFIGS: Record<
  string,
  ApplicationDeploymentConfig
> = {
  FLEET_CONFIGURATION_DASHBOARD: {
    ORG_ID: VERCEL_ORG_ID.DEFAULT,
    PROJECT_ID: VERCEL_PROJECT.FLEET_CONFIGURATION_DASHBOARD.ID,
    PROJECT_NAME: VERCEL_PROJECT.FLEET_CONFIGURATION_DASHBOARD.NAME,
    DOMAIN: VERCEL_PROJECT.FLEET_CONFIGURATION_DASHBOARD.ALIAS,
    SCOPE: VERCEL_SCOPE.EQUIPMENTSHARE,
  } as const,
};

/**
 * Vercel configuration for all projects
 */
export enum VercelEnv {
  PRODUCTION = "production",
  PREVIEW = "preview",
}

export const VERCEL = {
  ...APPLICATION_DEPLOYMENT_CONFIGS,
} as const;

export type VercelType = typeof VERCEL;
