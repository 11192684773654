import {
  EuiIcon,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  EuiKeyPadMenuProps,
  htmlIdGenerator,
} from "@equipmentshare/ds2";
import React from "react";

import { FormFieldRow } from "../form-field-row";
import { FormFieldProps } from "../types";

export type TileMenuItemOption = {
  icon: string | React.ComponentType<React.SVGProps<SVGSVGElement>>;
  isDisabled?: boolean;
  key?: string;
  label: string;
  onHoverContent?: React.ReactNode;
};

export type TileMenuProps = FormFieldProps &
  EuiKeyPadMenuProps & {
    options: TileMenuItemOption[];
  } & { onHoverInfoText?: string; value?: string };

export const TileMenu = ({
  checkable = true,
  errorMessage,
  isInvalid,
  isRequired = false,
  label,
  labelAppend,
  onChange,
  onHoverInfoText,
  options,
  value,
}: TileMenuProps) => {
  return (
    <FormFieldRow
      errorMessage={errorMessage}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      labelAppend={labelAppend}
      onHoverInfoText={onHoverInfoText}
    >
      <EuiKeyPadMenu checkable={checkable} data-testid="tile-menu">
        {options.map((option, i) => (
          <EuiKeyPadMenuItem
            aria-label={option.label}
            checkable="single"
            data-testid={`tile-menu-item-${i}`}
            id={option.label}
            isDisabled={option.isDisabled ?? false}
            isSelected={value === option.label}
            key={option.key ?? htmlIdGenerator()()}
            label={option.label}
            name={option.label}
            onChange={onChange}
          >
            <EuiIcon size="l" type={option.icon} />
          </EuiKeyPadMenuItem>
        ))}
      </EuiKeyPadMenu>
    </FormFieldRow>
  );
};
