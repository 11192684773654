import { ReactNode, useEffect, useRef } from "react";

import { DataVizContainerDimensionsDefaults } from "../../../types";

export type ResponsivePieWrapperProps = DataVizContainerDimensionsDefaults & {
  children: ReactNode;
  svgTestId?: string;
};

export const DATA_TEST_ID = "dataviz-responsive-pie-wrapper";

export const ResponsivePieWrapper = (props: ResponsivePieWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  /**
   * Nivo components do not support data-testids on the svg element so we need to manually add them.
   * Due to the way Nivo renders the svg element we can't just use a ref to check for the svg element,
   * instead we use a MutationObserver to watch for changes in the child elements of the wrapper and
   * add the data-testid attribute to the svg element when it is rendered.
   */
  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      const svgElement = wrapperRef.current?.querySelector("svg");

      if (svgElement) {
        svgElement.setAttribute(
          "data-testid",
          props.svgTestId ?? `${DATA_TEST_ID}-svg`,
        );
        observer.disconnect();
      }
    });

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current, { childList: true, subtree: true });
    }

    return () => {
      // Cleanup observer when component unmounts
      observer.disconnect();
    };
  }, [props.svgTestId]);

  return (
    <div
      data-testid={DATA_TEST_ID}
      ref={wrapperRef}
      style={{
        minWidth: props.containerMinWidth ?? 0,
        minHeight: props.containerMinHeight ?? "50%",
        width: props.containerWidth ?? "99%", // Known issue requires 99% to ensure proper responsiveness
        height: props.containerHeight ?? "99%", // Known issue requires 99% to ensure proper responsiveness
      }}
    >
      {props.children}
    </div>
  );
};
