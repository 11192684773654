import { FlyoutContextKey } from "./flyout";
import * as layout from "./layout";
import * as routes from "./routes";

export const CONSTANTS = {
  APP_NAME: "fleet-configuration-dashboard",
  PARENT_WINDOW_MESSAGE_TYPES: {
    T3_IFRAME_TRIGGERED_ROUTE: "t3iFrameTriggeredRoute",
    T3_IFRAME_TRIGGERED_INTERCOM_MESSAGE: "t3iFrameTriggeredIntercomMessage",
  },
  FlyoutContextKey,
  LOCAL_STORAGE: {
    FILTER_STATE_BASE_KEY: "DEVICE_FILTER_STATE",
  },
  ...layout,
  ...routes,
};
