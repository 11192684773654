import { useEuiTheme } from "@equipmentshare/ds2";
import { type ComputedDatum } from "@nivo/pie";

import type { DataVizBaseDatum } from "../../../../types";

export type CenteredMetricProps = {
  centerX: number;
  centerY: number;
  dataWithArc: readonly ComputedDatum<DataVizBaseDatum>[];
  innerRadius: number;
  metricLabel?: string;
  metricOverride?: number | string;
};

const renderSvgDropShadow = () => (
  <defs>
    <filter height="200%" id="drop-shadow" width="200%" x="-50%" y="-50%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
      <feOffset dx="2" dy="2" result="offsetblur" />
      <feFlood floodColor="rgba(0,0,0,0.08)" />
      <feComposite in2="offsetblur" operator="in" />
      <feMerge>
        <feMergeNode />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  </defs>
);

export const CenteredMetric = (props: CenteredMetricProps) => {
  const { euiTheme } = useEuiTheme();

  const getMetricValue = () => {
    let total = 0;
    if (props.metricOverride !== undefined) {
      total = Number(props.metricOverride);
    } else {
      props.dataWithArc.forEach((datum) => {
        total += datum.value;
      });
    }

    return total.toLocaleString();
  };

  const METRIC_PADDING = props.metricLabel ? 6 : 0;
  const METRIC_FONT_SIZE = 36;
  const METRIC_LABEL_FONT_SIZE = 14;
  const METRIC_LABEL_OFFSET = METRIC_FONT_SIZE / 2 + METRIC_PADDING;

  return (
    <svg>
      {renderSvgDropShadow()}
      <circle
        cx={props.centerX}
        cy={props.centerY}
        data-testid="center-metric-circle"
        r={props.centerY * props.innerRadius}
        style={{ fill: "white", filter: "url(#drop-shadow)" }}
      />
      <text
        data-testid="center-metric-value"
        dominantBaseline="central"
        style={{
          fontSize: `${METRIC_FONT_SIZE}px`,
          fontWeight: 600,
        }}
        textAnchor="middle"
        x={props.centerX}
        y={props.centerY - METRIC_PADDING}
      >
        {getMetricValue()}
      </text>
      {props.metricLabel && (
        <text
          data-testid="center-metric-label"
          dominantBaseline="central"
          style={{
            fontSize: `${METRIC_LABEL_FONT_SIZE}px`,
            fontWeight: 400,
            fill: euiTheme.colors.subduedText,
          }}
          textAnchor="middle"
          x={props.centerX}
          y={props.centerY + METRIC_LABEL_OFFSET}
        >
          {props.metricLabel}
        </text>
      )}
    </svg>
  );
};
