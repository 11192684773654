import { EuiAccordion, EuiText, htmlIdGenerator } from "@equipmentshare/ds2";
import { LIGHT_BORDER } from "@fleet-configuration/utils";
import React from "react";

export type AccordionProps = {
  children: React.ReactNode;
  title: string;
};

export const Accordion = ({ children, title }: AccordionProps) => {
  return (
    <EuiAccordion
      arrowProps={{ iconSize: "l" }}
      buttonContent={
        <EuiText size="s">
          <strong>{title}</strong>
        </EuiText>
      }
      data-testid="accordion"
      id={htmlIdGenerator()()}
      initialIsOpen
      style={{ borderBottom: `${LIGHT_BORDER}`, padding: "16px" }}
    >
      {children}
    </EuiAccordion>
  );
};
