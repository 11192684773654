import { ComboBoxField } from "../combo-box-field";
import { FormFieldProps } from "../types";

type YearFieldProps = {
  mostRecentYear?: number;
  oldestYear?: number;
} & Omit<FormFieldProps, "label"> &
  Partial<Pick<FormFieldProps, "label">>;

const nextYear = () => new Date().getFullYear() + 1;

export const YearField = ({
  errorMessage,
  isInvalid = false,
  label = "Year",
  labelAppend,
  mostRecentYear = nextYear(),
  oldestYear = 1900,
  onChange,
  value,
}: YearFieldProps) => {
  const years = Array.from(
    { length: mostRecentYear - oldestYear + 1 },
    (_, i) => mostRecentYear - i,
  );
  const yearOptions = years.map((year) => ({
    label: `${year}`,
    value: `${year}`,
  }));

  return (
    <ComboBoxField
      errorMessage={errorMessage}
      isInvalid={isInvalid}
      label={label}
      labelAppend={labelAppend}
      onChange={onChange}
      options={yearOptions}
      value={value}
    />
  );
};
