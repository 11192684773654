import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
} from "@equipmentshare/ds2";
import { ReactNode, useState } from "react";

import { ActionConfig } from "../../types";

export type WizardStepConfig = {
  title: string;
  body: string | ReactNode;
  primaryAction?: ActionConfig;
  secondaryAction?: ActionConfig;
};

type ModalWizardProps = {
  steps: WizardStepConfig[];
  showModal: boolean;
  onClose: () => void;
  currentStepIndex?: number;
};

export const useModalWizardState = () => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return {
    currentStepIndex,
    setCurrentStepIndex,
    showModal,
    openModal,
    closeModal,
  };
};

export const ModalWizard = ({
  steps,
  showModal,
  onClose,
  currentStepIndex = 0,
}: ModalWizardProps) => {
  const currentStep = steps[currentStepIndex];
  return showModal ? (
    <EuiModal data-testid="modal-wizard" onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{currentStep.title}</EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        {typeof currentStep.body === "string" ? (
          <EuiText>{currentStep.body}</EuiText>
        ) : (
          currentStep.body
        )}
      </EuiModalBody>

      <EuiModalFooter>
        {currentStep.secondaryAction && (
          <EuiButtonEmpty onClick={currentStep.secondaryAction.action}>
            {currentStep.secondaryAction.label}
          </EuiButtonEmpty>
        )}
        {currentStep.primaryAction && (
          <EuiButton fill onClick={currentStep.primaryAction.action}>
            {currentStep.primaryAction.label}
          </EuiButton>
        )}
      </EuiModalFooter>
    </EuiModal>
  ) : null;
};
