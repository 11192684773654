import { wait } from "./wait";

/**
 * Poll with backoff.
 * Default to 5 polls with a 1s initial wait time, adding 1s each time.
 * i.e. immediate -> 1s -> 2s -> 3s -> 4s
 */
export async function pollWithBackoff(
  fn: () => Promise<any>,
  opts: { limit: number; waitMs: number } = { limit: 5, waitMs: 1_000 },
  depth = 1,
) {
  await fn();
  if (depth >= opts.limit) {
    return;
  }
  await wait(opts.waitMs);
  return pollWithBackoff(
    fn,
    { ...opts, waitMs: opts.waitMs + 1_000 },
    depth + 1,
  );
}
