import { DebouncedFunc, debounce as lodashDebounce } from "lodash";

import { SEARCH_DEBOUNCE_DELAY } from "./config";

type CallBack = (args?: any) => void;

export const debounce = (
  fn: CallBack,
  wait: number = SEARCH_DEBOUNCE_DELAY,
): DebouncedFunc<CallBack> => {
  return lodashDebounce(fn, wait);
};
