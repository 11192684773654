import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  IconType,
} from "@equipmentshare/ds2";

export type IconButtonProps = {
  href?: string;
  iconType: IconType;
  isDisabled?: boolean;
  isExternal?: boolean;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
};

export const IconButton = ({
  href,
  iconType,
  isDisabled = false,
  isLoading = false,
  isExternal = false,
  label,
  onClick,
}: IconButtonProps) => {
  const testIdPrefix = isExternal ? "external-" : "";
  return (
    <EuiFlexGroup
      alignItems="center"
      data-testid="icon-button"
      direction="column"
      gutterSize="xs"
    >
      <EuiFlexItem>
        <EuiButtonIcon
          aria-label={label}
          data-testid={`${testIdPrefix}icon-button-${label}`}
          display="base"
          href={href}
          iconType={iconType}
          isDisabled={isDisabled || isLoading}
          isLoading={isLoading}
          onClick={onClick}
          size="m"
          target={isExternal ? "_blank" : undefined}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonEmpty
          aria-label={label}
          data-testid={`${testIdPrefix}empty-button-${label}`}
          href={href}
          isDisabled={isDisabled || isLoading}
          onClick={onClick}
          size="xs"
          target={isExternal ? "_blank" : undefined}
        >
          {label}
        </EuiButtonEmpty>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
