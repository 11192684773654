import { Asset, Camera, Device, Tracker } from "@fleet-configuration/client";
import { createContext, useContext, useState } from "react";

type AssetId = Asset["assetId"];
type DeviceId = Tracker["trackerId"] | Camera["cameraId"] | Device["esId"];

type PendingAssignmentsMap = Record<DeviceId, AssetId>;

type PendingAssignmentsContextValue = {
  pendingAssignments: PendingAssignmentsMap;
  setPendingAssignment: (deviceId: DeviceId, assetId: AssetId) => void;
};

/*
 * PendingAssignmentsContext and PendingAssignmentsProvider are used to manage the state of pending assignments of a device to an asset.
 * This context is used to allow various UI elements to reflect a change in device install status from unassigned to assigned,
 * without having to wait for the assignment to propagate through the system.
 *
 * Pending assignments can be looked up by device ID from the `pendingAssignments` object to retrieve the asset ID that the device is pending assignment to.
 * Pending assignments can be set by device ID and asset ID to update the pending assignments state using `setPendingAssignment`.
 */
const PendingAssignmentsContext = createContext<PendingAssignmentsContextValue>(
  {
    pendingAssignments: {},
    setPendingAssignment: () => {},
  },
);

type PendingAssignmentsProviderProps = {
  children: React.ReactNode;
};

export const PendingAssignmentsProvider = ({
  children,
}: PendingAssignmentsProviderProps) => {
  const [pendingAssignments, setPendingAssignments] =
    useState<PendingAssignmentsMap>({});

  const value = {
    pendingAssignments,
    setPendingAssignment: (deviceId: DeviceId, assetId: AssetId) => {
      setPendingAssignments((prev) => ({
        ...prev,
        [deviceId]: assetId,
      }));
    },
  };

  return (
    <PendingAssignmentsContext.Provider value={value}>
      {children}
    </PendingAssignmentsContext.Provider>
  );
};

export const usePendingAssignments = () => {
  const context = useContext(PendingAssignmentsContext);

  return context;
};
