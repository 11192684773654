import { EuiFlexItem, EuiImage } from "@equipmentshare/ds2";

type DeviceImageProps = {
  alt: string;
  src: string;
};

const HEIGHT_AND_WIDTH = "72px";

export const DeviceImage = ({ src, alt }: DeviceImageProps) => {
  return (
    <EuiFlexItem
      grow={false}
      style={{
        width: HEIGHT_AND_WIDTH,
        height: HEIGHT_AND_WIDTH,
        maxHeight: HEIGHT_AND_WIDTH,
        maxWidth: HEIGHT_AND_WIDTH,
        backgroundColor: "lightgray",
        borderRadius: "6px",
        marginRight: "8px",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <EuiImage
        alt={alt}
        data-testid="device-image"
        size="fullWidth"
        src={src}
      />
    </EuiFlexItem>
  );
};
