import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSkeletonText,
  EuiText,
} from "@equipmentshare/ds2";
import { ReactNode } from "react";

import { Tooltip } from "../tooltip";

export type DetailColumnProps = {
  color?: string;
  isLoading?: boolean;
  label: string;
  labelColor?: string;
  onHoverInfoText?: string;
  value: string | number | ReactNode;
};

export const DetailColumn = ({
  color,
  isLoading = false,
  label,
  labelColor,
  onHoverInfoText,
  value,
}: DetailColumnProps) => {
  return (
    <EuiFlexGroup
      data-testid={`detail-column-${label}`}
      direction="column"
      gutterSize="none"
    >
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="xs" responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiText color={labelColor ?? "subdued"} size="s">
              {label}
            </EuiText>
          </EuiFlexItem>
          {onHoverInfoText && (
            <EuiFlexItem>
              <Tooltip
                anchorPosition="upCenter"
                panelStyle={{ width: "260px" }}
                trigger={
                  <EuiIcon
                    color="subdued"
                    data-testid="info-icon"
                    type="questionInCircle"
                  />
                }
              >
                {onHoverInfoText}
              </Tooltip>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem>
        {isLoading ? (
          <EuiSkeletonText
            data-testid="loading-detail-column"
            isLoading={isLoading}
            lines={1}
          />
        ) : typeof value === "string" || typeof value === "number" ? (
          <EuiText color={color} size="s">
            {value}
          </EuiText>
        ) : (
          value
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
