import { createContext, ReactNode, useContext, useState } from "react";

type BaseFlyoutProps = {
  hideOverlay?: boolean;
};

type FlyoutContexts<GenericFlyoutProps> = {
  [key: string]: FlyoutContext<GenericFlyoutProps>;
};

type FlyoutContext<GenericFlyoutProps> = {
  isFlyoutVisible: boolean;
  closeFlyout: () => void;
  openFlyout: () => void;
  flyoutProps: GenericFlyoutProps & BaseFlyoutProps;
  setFlyoutProps: (props: GenericFlyoutProps & BaseFlyoutProps) => void;
};

const defaultContext: FlyoutContexts<any> = {};

const FlyoutContext = createContext(defaultContext);

const createInitialState = <GenericFlyoutProps extends object>(
  keys: string[],
): FlyoutContexts<GenericFlyoutProps> => {
  const initialState: FlyoutContexts<GenericFlyoutProps> = {};
  keys.forEach((key) => {
    initialState[key] = {
      isFlyoutVisible: false,
      closeFlyout: () => {},
      openFlyout: () => {},
      flyoutProps: {} as GenericFlyoutProps,
      setFlyoutProps: () => {},
    };
  });
  return initialState;
};

/**
 * FlyoutContext and FlyoutForm are not intrinsically linked, but the FlyoutContext exists
 * as an optional method for managing the visible state of the FlyoutForm component, as well as props that can be used within the component.
 *
 * Usage:
 * ```
 * const key = "foo" // some meaningful key that represents an instance of a flyout
 * const { isFlyoutVisible, openFlyout } = useFlyout(key);
 * return <FlyoutForm isVisible={isFlyoutVisible} />
 * ```
 *
 * `openFlyout` and `closeFlyout` may then be called from anywhere nested under FlyoutProvider to toggle the visibility of the FlyoutForm, and `setFlyoutProps` can be used to set values which can be retrieved using `flyoutProps`.
 */
export const FlyoutProvider = <GenericFlyoutProps extends object>(props: {
  children: ReactNode;
  keys: string[];
}) => {
  const initialState = createInitialState<GenericFlyoutProps>(props.keys);
  const [flyoutStates, setFlyoutStates] = useState(initialState);
  const [flyoutProps, _setFlyoutProps] = useState(initialState);

  const setFlyoutVisibility = (key: string) => (visible: boolean) => {
    setFlyoutStates((prevStates) => ({
      ...prevStates,
      [key]: {
        ...prevStates[key],
        isFlyoutVisible: visible,
      },
    }));
  };

  const setPropsForFlyout = (key: string) => (props: GenericFlyoutProps) => {
    _setFlyoutProps((prevStates) => ({
      ...prevStates,
      [key]: {
        ...prevStates[key],
        flyoutProps: props,
      },
    }));
  };

  const value = Object.keys(flyoutStates).reduce((acc, key) => {
    acc[key] = {
      isFlyoutVisible: flyoutStates[key].isFlyoutVisible,
      closeFlyout: () => {
        setFlyoutVisibility(key)(false);
        setPropsForFlyout(key)({} as GenericFlyoutProps);
      },
      openFlyout: () => {
        setFlyoutVisibility(key)(true);
      },
      flyoutProps: flyoutProps[key].flyoutProps,
      setFlyoutProps: setPropsForFlyout(key),
    };
    return acc;
  }, {} as FlyoutContexts<GenericFlyoutProps>);

  return (
    <FlyoutContext.Provider value={value}>
      {props.children}
    </FlyoutContext.Provider>
  );
};

export const useFlyout = <GenericFlyoutProps extends object>(
  key: string,
): FlyoutContext<GenericFlyoutProps> => {
  const context = useContext(FlyoutContext);
  return context[key];
};
