import {
  EuiPopover,
  EuiPopoverFooter,
  EuiPopoverTitle,
  EuiThemeColorMode,
} from "@equipmentshare/ds2";
import type { EuiPopoverProps } from "@equipmentshare/ds2";
import React, { ReactElement, ReactNode, useState } from "react";

import { ThemeWrapper } from "../theme-wrapper/theme-wrapper";
/**
 * All available props can be viewed here:
 * https://eui.elastic.co/v87.2.0/#/layout/popover
 */
export type TooltipProps = Omit<
  EuiPopoverProps,
  "button" | "closePopover" | "isOpen" | "ownFocus" | "title"
> & {
  children: ReactNode;
  colorMode?: EuiThemeColorMode;
  footer?: ReactNode;
  testIdSuffix?: string;
  title?: ReactNode;
  trigger: Omit<ReactElement, "onMouseEnter" | "onMouseLeave">;
  triggerColorMode?: EuiThemeColorMode;
};

export const Tooltip = (props: TooltipProps) => {
  // Destructure props that should not be applied to the EuiPopover
  const {
    colorMode = "dark",
    footer,
    testIdSuffix,
    title,
    trigger,
    triggerColorMode = "inverse",
    ...popoverProps
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const openPopover = () => setIsPopoverOpen(true);
  const closePopover = () => setIsPopoverOpen(false);

  // Ensure that any onMouseEvent handlers on the trigger are overridden to avoid race conditions
  const triggerWithHandlers = React.cloneElement(props.trigger, {
    onMouseEnter: openPopover,
    onMouseLeave: closePopover,
  });

  const dataTestId = `tooltip${testIdSuffix ? `-${testIdSuffix}` : ""}`;

  return (
    // ThemeWrapper is necessary around both the EuiPopover and Children to ensure the correct colorMode is applied to both the tooltip and its children
    <ThemeWrapper colorMode={colorMode}>
      <EuiPopover
        button={
          <ThemeWrapper colorMode={triggerColorMode}>
            {triggerWithHandlers}
          </ThemeWrapper>
        }
        closePopover={closePopover}
        data-testid={dataTestId}
        isOpen={isPopoverOpen}
        ownFocus={false}
        style={{ verticalAlign: "baseline" }}
        {...popoverProps}
      >
        <ThemeWrapper colorMode={props.colorMode}>
          {title && (
            <EuiPopoverTitle data-testid={`${dataTestId}-title`}>
              {title}
            </EuiPopoverTitle>
          )}

          {props.children}
          {footer && (
            <EuiPopoverFooter data-testid={`${dataTestId}-footer`}>
              {footer}
            </EuiPopoverFooter>
          )}
        </ThemeWrapper>
      </EuiPopover>
    </ThemeWrapper>
  );
};
