import { EuiComboBox, EuiComboBoxOptionOption } from "@equipmentshare/ds2";
import React, { useEffect, useState } from "react";

import { FormFieldRow } from "../form-field-row";
import { FormFieldProps } from "../types";

export type ComboBoxProps = FormFieldProps & {
  isClearable?: boolean;
  onSearchChange?: (searchValue: string) => void;
  options?: EuiComboBoxOptionOption[];
  renderOption?: (option: EuiComboBoxOptionOption) => React.ReactNode;
  rowHeight?: number;
  selectedOptions?: EuiComboBoxOptionOption[];
  singleSelection?: boolean;
};

export const ComboBox = ({
  errorMessage,
  helpText,
  isClearable = true,
  isDisabled = false,
  isInvalid,
  isLoading = false,
  isRequired,
  label,
  labelAppend,
  onChange,
  onSearchChange,
  onHoverInfoText,
  options = [],
  placeholder = "Select",
  renderOption,
  rowHeight,
  selectedOptions,
  value,
}: ComboBoxProps) => {
  const [selectedValues, setSelected] = useState<EuiComboBoxOptionOption[]>([]);

  useEffect(() => {
    setSelected(selectedOptions ?? []);
  }, [selectedOptions]);

  useEffect(() => {
    if (!value) {
      setSelected([]);
    }
  }, [value]);

  const handleChange = (selectedOptions: EuiComboBoxOptionOption[]) => {
    const selectedValue =
      selectedOptions.length > 0 ? selectedOptions[0].value : "";
    onChange(selectedValue);
  };

  return (
    <FormFieldRow
      errorMessage={errorMessage}
      helpText={helpText}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      labelAppend={labelAppend}
      onHoverInfoText={onHoverInfoText}
    >
      <EuiComboBox
        aria-label={`${label}`}
        data-testid="combo-box"
        fullWidth
        id="combo-box"
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={handleChange}
        onSearchChange={onSearchChange}
        options={options}
        placeholder={placeholder}
        renderOption={renderOption}
        rowHeight={rowHeight}
        selectedOptions={selectedValues}
        singleSelection={{ asPlainText: true }}
      />
    </FormFieldRow>
  );
};
