import { EuiFormRow } from "@equipmentshare/ds2";
import { ReactElement } from "react";

import { FormRowLabel } from "./form-row-label";

import { FormFieldProps } from "../types";

export type FormFieldRowProps = Omit<FormFieldProps, "onChange" | "value"> & {
  children: ReactElement;
};

export const FormFieldRow = ({
  children,
  errorMessage,
  helpText,
  isInvalid,
  isRequired,
  label,
  labelAppend,
  onHoverInfoText,
}: FormFieldRowProps) => {
  return (
    <EuiFormRow
      error={errorMessage}
      fullWidth
      helpText={helpText}
      isInvalid={isInvalid}
      label={
        <FormRowLabel
          isRequired={isRequired}
          label={label}
          onHoverInfoText={onHoverInfoText}
        />
      }
      labelAppend={labelAppend}
    >
      {children}
    </EuiFormRow>
  );
};
