import { EuiFieldText } from "@equipmentshare/ds2";

import { FormFieldRow } from "../form-field-row";
import { FormFieldProps } from "../types";

export const TextField = ({
  errorMessage,
  helpText,
  isInvalid = false,
  inputRef,
  isRequired = false,
  label,
  labelAppend,
  onChange,
  onHoverInfoText,
  testId,
  value,
  isDisabled,
}: FormFieldProps) => {
  return (
    <FormFieldRow
      errorMessage={errorMessage}
      helpText={helpText}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      labelAppend={labelAppend}
      onHoverInfoText={onHoverInfoText}
    >
      <EuiFieldText
        aria-label={label}
        data-testid={testId}
        disabled={isDisabled}
        fullWidth
        inputRef={inputRef}
        onChange={onChange}
        value={value as string}
      />
    </FormFieldRow>
  );
};
