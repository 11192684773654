import { euiPaletteColorBlindBehindText } from "@equipmentshare/ds2";
import { COLOR_SCALE, COLOR_SHADE } from "@equipmentshare/t3-design-tokens";

export const COLORS = {
  SUCCESS: COLOR_SCALE.SUCCESS[500],
  LIGHT: COLOR_SHADE.LIGHT,
  LIGHTEST: COLOR_SHADE.LIGHTEST,
  DANGER: COLOR_SCALE.DANGER[500],
  DEVICE_INSTALL_STATUS: {
    STATUS_COMPLETE: euiPaletteColorBlindBehindText()[1],
    STATUS_DEFAULT: euiPaletteColorBlindBehindText()[5],
  },
};
