import { type EuiThemeColorMode, EuiThemeProvider } from "@equipmentshare/ds2";
import { type ReactNode } from "react";

export type ThemeWrapperProps = {
  children: ReactNode;
  colorMode?: EuiThemeColorMode;
};

/**
 * Additional information can be found about rendering components with a specific colorMode here:
 * https://eui.elastic.co/v87.2.0/#/theming/color-mode#rendering-a-specific-color-mode
 */
export const ThemeWrapper = (props: ThemeWrapperProps) => (
  <EuiThemeProvider colorMode={props.colorMode}>
    {props.children}
  </EuiThemeProvider>
);
