import { ComputedDatum, ResponsivePie } from "@nivo/pie";

import { CenteredMetric } from "./center-metric";

import type {
  DataVizBaseDatum,
  DataVizContainerDimensionsDefaults,
} from "../../../types";
import { ResponsivePieWrapper } from "../responsive-pie-wrapper";
import { extractDatumColor, renderDataVizTooltip } from "../utils";

const ARC_INNER_RADIUS = 0.7;

const commonProperties = {
  margin: { top: 16, right: 0, bottom: 16, left: 0 },
  animate: true,
};

export type DonutWithCenterMetricProps = {
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: DataVizBaseDatum[];
  innerRadius?: number;
  metricLabel?: string;
  metricValue?: number | string;
  showTooltip?: boolean;
  testId?: string;
};

export const DonutWithCenterMetric = (props: DonutWithCenterMetricProps) => {
  const innerRadius = props.innerRadius || ARC_INNER_RADIUS;
  const centeredMetricValue =
    props.metricValue !== undefined ? `${props.metricValue}` : undefined;
  const { testId = "donut-with-center-metric" } = props;

  return (
    <ResponsivePieWrapper {...props.containerDimensions} svgTestId={testId}>
      <ResponsivePie
        {...commonProperties}
        colors={extractDatumColor}
        data={props.data}
        innerRadius={innerRadius}
        layers={[
          "arcs",
          "legends",
          (centeredMetricProps) => (
            <CenteredMetric
              centerX={centeredMetricProps.centerX}
              centerY={centeredMetricProps.centerY}
              dataWithArc={
                centeredMetricProps.dataWithArc as ComputedDatum<DataVizBaseDatum>[]
              }
              innerRadius={innerRadius}
              {...(props.metricLabel && { metricLabel: props.metricLabel })}
              {...(centeredMetricValue && {
                metricOverride: centeredMetricValue,
              })}
            />
          ),
        ]}
        tooltip={(e) =>
          props.showTooltip ? renderDataVizTooltip({ datum: e.datum }) : null
        }
      />
    </ResponsivePieWrapper>
  );
};
