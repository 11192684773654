import { ApolloError } from "@apollo/client";
import { isClientSide } from "@fleet-configuration/utils";
import { GraphQLError } from "graphql";

/**
 *
 * ⚠ WARNING ⚠ - This file is for CLIENT SIDE ONLY
 * Use within a server side context will result in an error
 */

/**
 * Generate an ApolloError with the given list of errors
 * and throw it.
 */
export const throwApolloError = (errors: string | string[]) => {
  if (isClientSide()) {
    throw new Error("throwApolloError is not available on the server side");
  }
  if (!Array.isArray(errors)) {
    errors = [errors];
  }
  throw new ApolloError({
    graphQLErrors: [
      ...errors.map((error) => {
        return new GraphQLError(error);
      }),
    ],
  });
};
