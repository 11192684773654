import { ArcLabelsProps, DatumWithArcAndColor } from "@nivo/arcs";
import { ResponsivePie } from "@nivo/pie";

import {
  DataVizBaseDatum,
  DataVizContainerDimensionsDefaults,
} from "../../../types";
import { ResponsivePieWrapper } from "../responsive-pie-wrapper";
import { renderDataVizTooltip } from "../utils";

export type ArcLabel = {
  color?: ArcLabelsProps<DatumWithArcAndColor>["arcLabelsTextColor"];
  showLabels?: boolean;
  skipAngle?: number;
};

export type PieModifiers = {
  arcLabelRenderer?: (labelValue: number | string) => string;
};

export type PieProps = {
  arcLabels?: ArcLabel;
  colors?: string[];
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: DataVizBaseDatum[];
  modifiers?: PieModifiers;
  showTooltip?: boolean;
  testId?: string;
};

export const Pie = (props: PieProps) => {
  const arcLabelRenderer = props?.modifiers?.arcLabelRenderer
    ? props.modifiers.arcLabelRenderer
    : (e: string | number) => String(e);
  const { testId = "responsive-pie" } = props;

  return (
    <ResponsivePieWrapper {...props.containerDimensions} svgTestId={testId}>
      <ResponsivePie
        activeOuterRadiusOffset={8}
        arcLabel={(datum) => arcLabelRenderer(datum.value)}
        arcLabelsRadiusOffset={0.75}
        arcLabelsSkipAngle={props?.arcLabels?.skipAngle}
        arcLabelsTextColor={props?.arcLabels?.color}
        colors={props.colors ? props.colors : (datum) => datum.data.color}
        data={props.data}
        enableArcLabels={props?.arcLabels?.showLabels || false}
        enableArcLinkLabels={false}
        innerRadius={0}
        margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
        motionConfig="default"
        tooltip={(e) =>
          props.showTooltip ? renderDataVizTooltip({ datum: e.datum }) : null
        }
        transitionMode="startAngle"
      />
    </ResponsivePieWrapper>
  );
};
