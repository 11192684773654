import { ApolloClient, InMemoryCache } from "@apollo/client";

import { getApolloLinks } from "./getApolloLinks";

import { CLIENT_CONSTANTS } from "../../src/constants";

export type ApolloClientConfig = {
  endpoint: string;
  environment: string;
  notificationsEndpoint: string;
};

export type GetApolloClientOpts = {
  config: ApolloClientConfig;
  getAccessTokenSilently: () => Promise<string>;
};

export const getApolloClient = ({
  config,
  getAccessTokenSilently,
}: GetApolloClientOpts) => {
  const connectToDevTools = () => {
    return config.environment !== CLIENT_CONSTANTS.Environment.Production;
  };

  return new ApolloClient({
    link: getApolloLinks({
      endpoint: config.endpoint,
      getAccessTokenSilently,
      notificationsEndpoint: config.notificationsEndpoint,
    }),
    cache: new InMemoryCache({
      typePolicies: {
        Aggregation: {
          keyFields: (obj, context) =>
            `${obj.__typename}:${obj.id}:${obj.name}`,
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
        Aggregations: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          },
        },
      },
    }),
    connectToDevTools: connectToDevTools(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
};
