import { EuiSelect, EuiSelectOption } from "@equipmentshare/ds2";
import { useEffect, useState } from "react";

import { FormFieldRow } from "../form-field-row";
import { FormFieldProps } from "../types";

export type SelectProps = FormFieldProps & {
  options: EuiSelectOption[];
  hasNoInitialSelection?: boolean;
  castValueToNumber?: boolean;
};

export const Select = ({
  options,
  value,
  onChange,
  isInvalid,
  isRequired,
  errorMessage,
  label,
  labelAppend,
  hasNoInitialSelection = false,
  testId,
  castValueToNumber,
  isDisabled,
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    /*
    This allows the form fields to be reset programmatically, e.g. when the form is reset by another component.
    */
    setSelectedValue(value);
  }, [value, setSelectedValue]);

  return (
    <FormFieldRow
      errorMessage={errorMessage}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      labelAppend={labelAppend}
    >
      <EuiSelect
        aria-label={label}
        data-testid={testId || "select"}
        disabled={isDisabled}
        fullWidth
        hasNoInitialSelection={hasNoInitialSelection}
        id="select"
        onChange={(e) => {
          const updatedValue = castValueToNumber
            ? Number(e.target.value)
            : e.target.value;

          setSelectedValue(updatedValue);
          onChange(updatedValue);
        }}
        options={options}
        value={selectedValue as string | number}
      />
    </FormFieldRow>
  );
};
