import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@equipmentshare/auth0-react";
import type { ReactNode } from "react";

import { ApolloClientConfig, getApolloClient } from "./getApolloClient";

export type ApolloWrapperProps = {
  children: ReactNode;
  config: ApolloClientConfig;
};

export const ApolloWrapper = (props: ApolloWrapperProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const apolloClient = getApolloClient({
    config: props.config,
    getAccessTokenSilently,
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
