import React, { useCallback, useEffect, useState } from "react";

export function useIsComponentVisible(): {
  isVisible: boolean;
  ref: React.RefCallback<HTMLElement>;
  clearIsVisible: () => void;
} {
  const [isVisible, setIsVisible] = useState(false);
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);

  const setRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setRefElement(node);
    }
  }, []);

  const clearIsVisible = () => setIsVisible(false);

  useEffect(() => {
    if (refElement && !isVisible) {
      // More info here https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      const observer = new IntersectionObserver(
        ([entry]) => entry.isIntersecting && setIsVisible(true),
      );
      observer.observe(refElement);

      return () => {
        observer.disconnect();
      };
    }
  }, [isVisible, refElement]);

  return { isVisible, ref: setRef, clearIsVisible };
}
