/**
 * CONSTANT for string replacement as "%s"
 */
export const STRING_REPLACE_IDENTIFIER = "%s" as const;

/**
 * Take a string and replace the first instance of STRING_REPLACE_IDENTIFIER
 * If STRING_REPLACE_IDENTIFIER is not found, return the original string
 */
export const replaceStringPart = (
  str: string,
  value: string | number,
): string => {
  let _str = String(str);
  if (_str.includes(STRING_REPLACE_IDENTIFIER)) {
    _str = _str.replace(STRING_REPLACE_IDENTIFIER, String(value));
  }

  return _str;
};
