import { CLIENT_CONSTANTS } from "@fleet-configuration/client";
import { createContext, ReactNode, useContext } from "react";

import {
  useDeviceInstallStatus,
  UseDeviceInstallStatusResponse,
} from "@/hooks/useDeviceInstallStatus";
import { DeviceInstallStatusEnum } from "@/types/gql";

type DeviceInstallStatusContextValue = UseDeviceInstallStatusResponse & {
  refetch: () => void;
};

const DeviceInstallStatusContext = createContext<
  DeviceInstallStatusContextValue | undefined
>(undefined);

export const useDeviceInstallStatusContext = () => {
  const context = useContext(DeviceInstallStatusContext);

  if (!context) {
    throw new Error(
      "useDeviceInstallStatusContext must be used within a DeviceInstallStatusProvider",
    );
  }
  return context;
};

export const DeviceInstallStatusProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { deviceInstallStatus, error, loading, refetch } =
    useDeviceInstallStatus({
      // The query `searchDevices` is shared with other components, disabling the cache to prevent unexpected behavior
      // Changing this will cause the widget values to change unexpectedly when other components update the cache
      fetchPolicy: "no-cache",
      pollInterval: CLIENT_CONSTANTS.POLLING_INTERVAL.VERY_LOW,
      statusFilter: [
        DeviceInstallStatusEnum.Complete,
        DeviceInstallStatusEnum.Undetected,
        DeviceInstallStatusEnum.Unassigned,
      ],
    });

  const contextValue = {
    deviceInstallStatus,
    loading,
    error,
    refetch,
  };

  return (
    <DeviceInstallStatusContext.Provider value={contextValue}>
      {children}
    </DeviceInstallStatusContext.Provider>
  );
};
