import {
  ApolloError,
  type ApolloQueryResult,
  DeviceInstallStatusQuery,
  Exact,
  FacetId,
  type QueryOptions,
  SearchInput,
  throwApolloError,
  useDeviceInstallStatusQuery,
} from "@fleet-configuration/client";
import { COLORS } from "@fleet-configuration/utils";
import { ReactNode } from "react";

import { DeviceInstallStatusEnum } from "@/types/gql";

export type UseDeviceInstallStatusOptions = {
  statusFilter?: DeviceInstallStatusEnum[];
  fetchPolicy?: QueryOptions["fetchPolicy"];
  pollInterval?: QueryOptions["pollInterval"];
};

export type DeviceInstallStatusItem = {
  color: string;
  detail?: ReactNode;
  formattedValue: string;
  id: DeviceInstallStatusEnum;
  label: DeviceInstallStatusEnum;
  value: number;
};

export type DeviceInstallStatus = {
  totalCount: number;
  deviceInstallStatusItems: DeviceInstallStatusItem[];
};

export type UseDeviceInstallStatusResponse = {
  deviceInstallStatus: DeviceInstallStatus;
  error: ApolloError | string | undefined;
  loading: boolean;
  refetch: (
    variables?: Partial<Exact<{ input: SearchInput }>>,
  ) => Promise<ApolloQueryResult<DeviceInstallStatusQuery>>;
};

export type EnrichedDeviceInstallStatusData = {
  totalCount: number;
  deviceInstallStatusItems: DeviceInstallStatusItem[];
};

export const DEFAULT_DEVICE_INSTALL_DATA: EnrichedDeviceInstallStatusData = {
  totalCount: 0,
  deviceInstallStatusItems: [],
};
export const DEVICE_INSTALL_STATUS_FACET_ID = FacetId.DeviceInstallStatus;

export const getColorByStatus = (status: DeviceInstallStatusEnum) => {
  switch (status) {
    case DeviceInstallStatusEnum.Complete:
      return COLORS.DEVICE_INSTALL_STATUS.STATUS_COMPLETE;
    default:
      return COLORS.DEVICE_INSTALL_STATUS.STATUS_DEFAULT;
  }
};

export const enrichDeviceInstallStatusData = (
  data: DeviceInstallStatusQuery | undefined,
  filter: DeviceInstallStatusEnum[] = [],
) => {
  if (!data?.searchDevices?.facets) {
    return DEFAULT_DEVICE_INSTALL_DATA;
  }

  const statusItems =
    data.searchDevices.facets
      .find((facet) => facet.facetId === DEVICE_INSTALL_STATUS_FACET_ID)
      ?.entries.filter(
        (entry) =>
          filter.length === 0 ||
          filter.includes(entry.id as DeviceInstallStatusEnum),
      )
      .map((entry) => ({
        color: getColorByStatus(entry.id as DeviceInstallStatusEnum),
        formattedValue: entry.count.toLocaleString(),
        id: entry.id as DeviceInstallStatusEnum,
        label: entry.id as DeviceInstallStatusEnum,
        value: entry.count,
      })) || [];

  return {
    totalCount: data.searchDevices.totalItems,
    deviceInstallStatusItems: statusItems,
  };
};

export const useDeviceInstallStatus = (
  options: UseDeviceInstallStatusOptions,
): UseDeviceInstallStatusResponse => {
  const { statusFilter } = options;
  const { data, error, loading, refetch } = useDeviceInstallStatusQuery({
    ...options,
    variables: {
      input: {
        pageSize: 0,
        query: "",
      },
    },
  });

  if (error) {
    console.error(error);
    if (error instanceof ApolloError) {
      const gqlError = error.graphQLErrors[0];
      throwApolloError(gqlError.message);
    }

    return {
      deviceInstallStatus: DEFAULT_DEVICE_INSTALL_DATA,
      error,
      loading,
      refetch,
    };
  }

  if (loading) {
    return {
      deviceInstallStatus: DEFAULT_DEVICE_INSTALL_DATA,
      error: undefined,
      loading,
      refetch,
    };
  }

  return {
    deviceInstallStatus: enrichDeviceInstallStatusData(data, statusFilter),
    error: undefined,
    loading,
    refetch,
  };
};
