"use client";

import { useFlags } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";

export const FeatureFlagGate = ({
  children,
  flag,
}: {
  children: ReactNode;
  flag: string;
}) => {
  const flags = useFlags();
  return flags?.[flag] ? children : null;
};
