import { Device } from "@fleet-configuration/client";

export enum FlyoutContextKey {
  ADD_USERS = "ADD_USERS",
  CREATE_ASSET = "CREATE_ASSET",
  INSTALL_DEVICES = "INSTALL_DEVICES",
  ASSIGN_DEVICES = "ASSIGN_DEVICES",
  YOUR_DEVICES = "YOUR_DEVICES",
  DEVICE_DETAILS = "DEVICE_DETAILS",
  FILTERS = "FILTERS",
}

export type AssignDevicesFlyoutProps = {
  device?: Device;
};

export type DeviceDetailsFlyoutProps = {
  device: Device;
};
