import { Auth0ProviderOptions } from "@equipmentshare/auth0-react";

export enum MockUserPermissionType {
  OWNER_MANAGER = "OWNER_MANAGER",
}

export class Mocks {
  userPermissionsType: MockUserPermissionType | undefined;

  constructor() {
    this.userPermissionsType =
      (process.env
        .NEXT_PUBLIC_MOCK_USER_PERMISSIONS_TYPE as MockUserPermissionType) ||
      undefined;
  }
}

export class AuthenticationOptions implements Auth0ProviderOptions {
  audience: string;
  clientId: string;
  domain: string;
  redirectUri: string;
  scope?: string;

  constructor() {
    this.audience = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE!;
    this.clientId = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!;
    this.domain = process.env.NEXT_PUBLIC_AUTH0_DOMAIN!;
    this.redirectUri = process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI!;
    this.scope = process.env.NEXT_PUBLIC_AUTH0_SCOPE;
  }
}

/**
 * Utility class wrapper for type safe reading of environment variables and providing defaults.
 */
export class Env {
  environment: string;
  isDev: boolean;
  mocks: Mocks | undefined;
  authenticationOptions: AuthenticationOptions;

  constructor() {
    this.environment =
      process.env.NEXT_PUBLIC_FLEET_CONFIGURATION_ENVIRONMENT || "development";
    this.isDev = this.environment === "development";
    this.mocks = this.isDev ? new Mocks() : undefined;
    this.authenticationOptions = new AuthenticationOptions();
  }
}

const env = new Env();
export default env;
