import { EuiCheckboxGroup } from "@equipmentshare/ds2";
import { useEffect, useState } from "react";

import { FormFieldRow } from "../form-field-row";
import { FormFieldProps } from "../types";

type CheckboxOption = {
  label: string;
  id: string;
};

type CheckboxGroupFieldProps = FormFieldProps<IdToSelectedMap> & {
  options: CheckboxOption[];
};

type IdToSelectedMap = Record<string, boolean>;

export const CheckboxGroupField = ({
  label,
  options,
  onChange,
  value,
}: CheckboxGroupFieldProps) => {
  const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] =
    useState<IdToSelectedMap>(value ?? {});

  const onChangeSelection = (optionId: string) => {
    const newCheckboxIdToSelectedMap = {
      ...checkboxIdToSelectedMap,
      ...{
        [optionId]: !checkboxIdToSelectedMap[optionId],
      },
    };
    onChange(newCheckboxIdToSelectedMap);
    setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap);
  };

  useEffect(() => {
    /*
    This allows the form fields to be reset programmatically, e.g. when the form is reset by another component.
    */
    setCheckboxIdToSelectedMap(value ?? {});
  }, [value, setCheckboxIdToSelectedMap]);

  return (
    <FormFieldRow label={label}>
      <EuiCheckboxGroup
        idToSelectedMap={checkboxIdToSelectedMap}
        onChange={onChangeSelection}
        options={options}
      />
    </FormFieldRow>
  );
};
