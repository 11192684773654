import { EuiComboBox, EuiComboBoxOptionOption } from "@equipmentshare/ds2";
import { ReactNode, useEffect, useState } from "react";

import { FormFieldRow } from "../form-field-row";
import { FormFieldProps } from "../types";

type ComboBoxFieldProps = {
  onHoverInfoText?: string;
  onSearchChange?: (searchValue: string, hasMatchingOptions?: boolean) => void;
  options: EuiComboBoxOptionOption[];
  renderOption?: (
    option: EuiComboBoxOptionOption,
    searchValue: string,
  ) => ReactNode;
  rowHeight?: number;
  testId?: string;
} & FormFieldProps;

export const ComboBoxField = ({
  errorMessage,
  helpText,
  isDisabled,
  isInvalid,
  isLoading,
  isRequired,
  label,
  labelAppend,
  onChange,
  onHoverInfoText,
  onSearchChange,
  options,
  placeholder,
  renderOption,
  rowHeight,
  testId = "combo-box-field",
  value,
}: ComboBoxFieldProps) => {
  const [selectedOptions, setSelectedOptions] = useState<
    EuiComboBoxOptionOption[]
  >([]);

  useEffect(() => {
    /*
    If form field is reset programmatically, remove any selected options
    since `handleChange` won't be triggered by field being reset programmatically.
    */
    const matchingOption = options.find((option) => option.value === value);

    if (!matchingOption && !value) {
      setSelectedOptions([]);
    } else if (matchingOption) {
      setSelectedOptions([matchingOption]);
    }
  }, [value, setSelectedOptions, options]);

  const handleChange = (selectedOptions: EuiComboBoxOptionOption[]) => {
    const [selectedOption] = selectedOptions;
    onChange(selectedOption?.value ?? "");
    setSelectedOptions(selectedOptions);
  };

  return (
    <FormFieldRow
      errorMessage={errorMessage}
      helpText={helpText}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      labelAppend={labelAppend}
      onHoverInfoText={onHoverInfoText}
    >
      <EuiComboBox
        async
        data-testid={testId}
        fullWidth
        isClearable={false}
        isDisabled={isDisabled}
        isLoading={isLoading}
        onChange={handleChange}
        onSearchChange={onSearchChange}
        options={options}
        placeholder={placeholder}
        renderOption={renderOption}
        rowHeight={rowHeight}
        selectedOptions={selectedOptions}
        singleSelection={{ asPlainText: true }}
      />
    </FormFieldRow>
  );
};
