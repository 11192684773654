export const useParentWindowCommunication = ({
  parentOrigin,
  sourceApp,
  type,
}: {
  parentOrigin: string;
  sourceApp: string;
  type: string;
}) => {
  return {
    notifyParentWindow: () =>
      window.parent.postMessage(
        {
          type: type,
          source: sourceApp,
        },
        parentOrigin,
      ),
    sendMessageToParentWindow: (messageToParent: string) =>
      window.parent.postMessage(
        {
          type: type,
          source: sourceApp,
          message: messageToParent,
        },
        parentOrigin,
      ),
  };
};
