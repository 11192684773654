import { useIdentity } from "@equipmentshare/auth0-react";
import { heapEvent, useHeaper } from "@equipmentshare/heaper";
import { Events } from "@equipmentshare/heaper/dist/types";

import { App, HeaperEnvironment } from "../../types";
import { configureDatadogRUM } from "../../utils/datadogRum";

type HeaperComponentProps = {
  appName: App;
};

export const heaperEvent = <K extends keyof Events>(
  eventName: K,
  eventProps: Events[K],
): void => {
  try {
    heapEvent(eventName, eventProps);
  } catch (e) {
    console.error(`Failed to send heap event '${eventName}'. ${e}`);
  }
};

export const Heaper = (props: HeaperComponentProps) => {
  const { accessToken, idToken } = useIdentity();

  const datadogRumInstance = configureDatadogRUM(props.appName);
  const heaperEnv =
    process.env.NEXT_PUBLIC_DD_ENV === "production"
      ? HeaperEnvironment.PRODUCTION
      : HeaperEnvironment.STAGING;

  useHeaper(props.appName, heaperEnv, {
    intercom: false,
    datadogRumInstance,
    idToken,
    accessToken,
  });

  return null;
};
