import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
  EuiTextColor,
} from "@equipmentshare/ds2";
import { InformationLineIcon } from "@equipmentshare/ds2-icons";

import { Tooltip } from "../../tooltip";
import { FormFieldProps } from "../types";

export type LabelProps = {
  isRequired?: boolean;
  label: string;
  onHoverInfoText?: FormFieldProps["onHoverInfoText"];
};

export const RequiredAsterisk = () => (
  <EuiTextColor color="danger">*</EuiTextColor>
);

export const FormRowLabel = ({
  isRequired,
  label,
  onHoverInfoText,
}: LabelProps) => {
  return (
    <EuiFlexGroup gutterSize="xs" responsive={false}>
      <EuiFlexItem grow={false}>
        <EuiText size="s">
          <strong>{label}</strong>
        </EuiText>
      </EuiFlexItem>
      {isRequired && (
        <EuiFlexItem grow={false}>
          <RequiredAsterisk />
        </EuiFlexItem>
      )}
      {onHoverInfoText && (
        <EuiFlexItem>
          <Tooltip
            anchorPosition="upCenter"
            trigger={
              <EuiIcon data-testid="info-icon" type={InformationLineIcon} />
            }
          >
            {onHoverInfoText}
          </Tooltip>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
};
