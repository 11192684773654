import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyoutFooter,
} from "@equipmentshare/ds2";
import { useState } from "react";

import { ActionConfig } from "../../types";

export type FooterProps = {
  primaryAction?: ActionConfig;
  secondaryAction?: ActionConfig;
  tertiaryAction?: ActionConfig;
};

const defaultPrimaryLabel = "Save";
const defaultSecondaryLabel = "Save & Add Another";
const defaultTertiaryLabel = "Cancel";

export const Footer = ({
  primaryAction,
  secondaryAction,
  tertiaryAction,
}: FooterProps) => {
  const [isPrimaryActionLoading, setIsPrimaryActionLoading] = useState(false);
  const [isSecondaryActionLoading, setIsSecondaryActionLoading] =
    useState(false);

  return (
    <EuiFlyoutFooter data-testid="flyout-footer">
      <EuiFlexGroup
        direction="rowReverse"
        gutterSize="s"
        justifyContent="spaceBetween"
      >
        <EuiFlexItem>
          <EuiFlexGroup direction="rowReverse" gutterSize="m">
            {primaryAction && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  data-testid="primary-action"
                  disabled={
                    isSecondaryActionLoading || primaryAction.isDisabled
                  }
                  fill
                  isLoading={isPrimaryActionLoading || primaryAction.isLoading}
                  onClick={async () => {
                    setIsPrimaryActionLoading(true);
                    await primaryAction.action();
                    setIsPrimaryActionLoading(false);
                  }}
                >
                  {primaryAction.label ?? defaultPrimaryLabel}
                </EuiButton>
              </EuiFlexItem>
            )}
            {secondaryAction && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  color="text"
                  data-testid="secondary-action"
                  disabled={
                    isPrimaryActionLoading || secondaryAction.isDisabled
                  }
                  fill={false}
                  isLoading={
                    isSecondaryActionLoading || secondaryAction.isLoading
                  }
                  onClick={async () => {
                    setIsSecondaryActionLoading(true);
                    await secondaryAction.action();
                    setIsSecondaryActionLoading(false);
                  }}
                  style={{ border: "1px solid #bcc1c5" }}
                >
                  {secondaryAction.label ?? defaultSecondaryLabel}
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
        {tertiaryAction && (
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty
              data-testid="tertiary-action"
              disabled={
                isPrimaryActionLoading ||
                isSecondaryActionLoading ||
                tertiaryAction.isDisabled
              }
              flush="left"
              onClick={tertiaryAction.action}
            >
              {tertiaryAction.label ?? defaultTertiaryLabel}
            </EuiButtonEmpty>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </EuiFlyoutFooter>
  );
};
