/**
 * ⚠ WARNING ⚠ - This file can be used in both CLIENT AND SERVER SIDE
 */

export enum Environment {
  Development = "development",
  Staging = "staging",
  Production = "production",
}

export const getEnv = () => {
  return process.env.NEXT_PUBLIC_FLEET_CONFIGURATION_ENVIRONMENT;
};

export const getEnvPrefix = () => {
  return getEnv() !== Environment.Production ? "staging-" : "";
};

export const getEnvUrl = (url: string) => {
  return `https://${getEnvPrefix()}${url}`;
};
