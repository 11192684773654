import { ApolloLink, createHttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";

import SSELink from "./SSELink";

export type GetApolloLinksOpts = {
  endpoint: string;
  getAccessTokenSilently: () => Promise<string>;
  notificationsEndpoint: string;
};

export const getApolloLinks = ({
  endpoint,
  getAccessTokenSilently,
  notificationsEndpoint,
}: GetApolloLinksOpts) => {
  const retryLink = new RetryLink({
    delay: { initial: 300, max: Infinity, jitter: true },
    attempts: {
      max: 5,
    },
  });

  const httpLink = createHttpLink({
    uri: (operation) => `${endpoint}?op=${operation.operationName}`,
  });

  const sseLink = new SSELink({
    url: notificationsEndpoint,
    // sse needs to supply its authorization header directly
    headers: async () => {
      const accessToken = await getAccessTokenSilently();
      return {
        Authorization: `Bearer ${accessToken}`,
      };
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    // use sse link for notification subscriptions
    sseLink,
    // otherwise use http link
    httpLink,
  );

  const authLink = setContext(async (_, { headers } = { headers: {} }) => {
    const accessToken = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    };
  });

  // authLink is a termininating link, so we need to concat it with the rest
  return authLink.concat(ApolloLink.from([retryLink, splitLink]));
};
