export type {
  Aggregation,
  Asset,
  AssetType,
  Branch,
  Camera,
  CameraType,
  ConfigurationSteps,
  CreateUser,
  CreateUserPermissions,
  Device,
  DeviceInstallStatusQuery,
  Facet,
  FacetEntry,
  Make,
  Me as User,
  Model,
  Notification,
  Permissions,
  Tracker,
  TrackerState,
  TrackerType,
  UpstreamFleetIngestionMetadata,
} from "@fleet-configuration/client";

// must re-export enums directly to be used as values
export {
  DeviceInstallStatus as DeviceInstallStatusEnum,
  StepStatus,
  NotificationType,
} from "@fleet-configuration/client";
